import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useAppDispatch } from "../app/hooks";
import { open } from "../features/alertSlice/alertSlice";

export function ResetPassword({ user }: { user: string }) {
    const [username, setUsername] = useState(user);
    const [code, setCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const dispatch = useAppDispatch();
  
    const reset = async (e: any) => {
      e.preventDefault();
  
      try {
        await Auth.forgotPasswordSubmit(username, code, newPassword);
        dispatch(open({ type: "success", message: `Password reset successfully!` }));
        setCode("");
        setNewPassword("");
        document.location.pathname = "/";
      } catch (error) {
        dispatch(open({ type: "error", message: `Password reset failed! ${JSON.stringify(error)}` }));
      }
    };
  
    return (
      <Box sx={{ mx: "auto", pt: 1, alignContent: "center", textAlign: "center" }}>
        <form onSubmit={reset}>
          <TextField
            required
            sx={{ pb: 2, width: 300 }}
            id="outlined-required"
            label="Nutzername"
            type="text"
            name="user"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            required
            sx={{ pb: 2, width: 300 }}
            id="outlined-required"
            label="Confirmation Code"
            type="code"
            name="code"
            autoComplete="false"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
  
          <TextField
            required
            sx={{ pb: 2, width: 300 }}
            id="outlined-required"
            label="New Password"
            type="password"
            name="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Button variant="contained" type="submit">
            Sign Up
          </Button>
        </form>
      </Box>
    );
  }
