import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { acceptFriendRequest, addFriendRequest } from "../../graphql/mutations";
import { queryMe, queryUser } from "../../graphql/queries";
import { checkIn } from "../locationsSlice/locationsSlice";

export interface Friend {
  friendName: string;
  createdAt: string;
  acceptedAt: string;
}

export interface FriendsRequest {
  friendName: string;
  requestFrom: string;
  requestTo: string;
  createdAt: string;
}

export interface UserDetails {
  id?: string;
  username?: string;
  image?: string;
  registeredAt?: string;
  email?: string;
  checkIns?: {
    location: string;
    createdAt: string;
  }[];
  friends?: Friend[];
  outgoingFriendRequests?: FriendsRequest[];
  incomingFriendRequests?: FriendsRequest[];
}

export interface UserIdentityState {
  status: "idle" | "loading" | "failed"; // TODO: do I need that and why?
  loginMe: {
    username?: string;
    attributes?: any;
    groups?: string[];
  };
  me: UserDetails;
  users: { [key: string]: UserDetails };
}

const initialState: UserIdentityState = {
  status: "idle",
  loginMe: {},
  me: {},
  users: {},
};

export const loginUser = createAsyncThunk(
  "userIdentity/login",
  async ({ user, password }: { user: string; password: string }) => {
    const login = await Auth.signIn(user, password);
    return {
      challengeName: login.challengeName,
    };
  }
);

export const getCurrentUser = createAsyncThunk("userIdentity/getCurrentUser", async () => {
  const userData = await Auth.currentAuthenticatedUser();
  return {
    username: userData.username,
    attributes: userData.attributes,
    groups: userData.signInUserSession.accessToken.payload["cognito:groups"],
  };
});

export const confirmUserSignUp = createAsyncThunk(
  "userIdentity/confirmUserSignUp",
  async ({ user, code }: { user: string; code: string }) => {
    await Auth.confirmSignUp(user, code);
  }
);

export const userSignUp = createAsyncThunk(
  "userIdentity/userSignUp",
  async ({ user, password, email }: { user: string; password: string; email: string }) => {
    await Auth.signUp({
      username: user,
      password,
      attributes: {
        email,
      },
    });
    return "success";
  }
);

export const getLoggedInUser = createAsyncThunk("userIdentity/getLoggedInuser", async () => {
  const user = await Auth.currentAuthenticatedUser();
  return {
    username: user.username,
    attributes: user.attributes,
    groups: user.signInUserSession.accessToken.payload["cognito:groups"],
  };
});

export const getMeDetails = createAsyncThunk("userIdentity/getMeDetails", async () => {
  const result = await API.graphql(graphqlOperation(queryMe));

  if ("data" in result && !("errors" in result)) {
    return {
      ...(result.data as any)?.queryMe.find((item: any) => item.username),
      checkIns: (result.data as any)?.queryMe.filter((item: any) => item.location),
      friends: (result.data as any)?.queryMe.filter((item: any) => item.friendName && item.acceptedAt),
      incomingFriendRequests: (result.data as any)?.queryMe.filter((item: any) => item.requestFrom && !item.acceptedAt),
      outgoingFriendRequests: (result.data as any)?.queryMe.filter((item: any) => item.requestTo && !item.acceptedAt),
    };
  }
});

export const getUserDetails = createAsyncThunk("userIdentity/getUserDetails", async (user: string) => {
  const result = await API.graphql(graphqlOperation(queryUser, { user: `USR#${user}` }));

  if ("data" in result && !("errors" in result)) {
    const userData = (result.data as any)?.queryUser.find((item: any) => item.username);
    return userData;
  }
});

export const requestFriend = createAsyncThunk("userIdentity/requestFriend", async (friend: string) => {
  const result = await API.graphql(graphqlOperation(addFriendRequest, { friend }));

  if ("data" in result && !("errors" in result)) {
    return (result.data as any)?.addFriendRequest;
  }
});

export const acceptRequest = createAsyncThunk("userIdentity/acceptFriend", async (friend: string) => {
  const result = await API.graphql(graphqlOperation(acceptFriendRequest, { friend }));

  if ("data" in result && !("errors" in result)) {
    return (result.data as any)?.acceptFriendRequest;
  }
});

export const userIdentitySlice = createSlice({
  name: "userIdentity",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkIn.fulfilled, (state, action) => {
        state.status = "idle";
        state.me.checkIns = [...((state.me?.checkIns as any[]) ?? []), action.payload];
      })
      .addCase(getLoggedInUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.loginMe = action.payload;
      })
      .addCase(confirmUserSignUp.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(userSignUp.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(getLoggedInUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.loginMe = action.payload;
      })
      .addCase(getMeDetails.fulfilled, (state, action) => {
        state.status = "idle";
        state.me = action.payload;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.status = "idle";
        state.users[action?.payload?.username] = action.payload;
      })
      .addCase(requestFriend.fulfilled, (state, action) => {
        state.status = "idle";
        state.me.outgoingFriendRequests?.push(action.payload as FriendsRequest);
      })
      .addCase(acceptRequest.fulfilled, (state, action) => {
        state.status = "idle";
        state.me.friends?.push(action.payload as Friend);
        state.me.incomingFriendRequests = state.me.incomingFriendRequests?.filter(
          (r) => r.friendName !== action.payload.friendName
        );
      });
  },
});

export default userIdentitySlice.reducer;
