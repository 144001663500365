import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { open } from "../features/alertSlice/alertSlice";

export function NewPassword({ user, oldPassword }: { user: string; oldPassword: string }) {
  const [newPassword, setNewPassword] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const set = async (e: any) => {
    e.preventDefault();

    try {
      const userData = await Auth.signIn(user, oldPassword);
      await Auth.completeNewPassword(userData, newPassword);

      dispatch(open({ type: "success", message: `Password reset successfully!` }));
      setNewPassword("");
      navigate("/lokale", { replace: true });
    } catch (error) {
      dispatch(open({ type: "error", message: `Password reset failed! ${JSON.stringify(error)}` }));
    }
  };

  return (
    <Box sx={{ mx: "auto", pt: 1, alignContent: "center", textAlign: "center" }}>
      <form onSubmit={set}>
        <TextField
          required
          sx={{ pb: 2, width: 300 }}
          id="outlined-required"
          label="New Password"
          type="password"
          name="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <br />
        <Button variant="contained" type="submit">
          Sign Up
        </Button>
      </form>
    </Box>
  );
}
