import API from "@aws-amplify/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Auth, graphqlOperation } from "aws-amplify";
import { queryType } from "../../API";
import { awsExports } from "../../aws-exports";
import { addCheckin } from "../../graphql/mutations";
import { getLocationPdf, queryByType, queryLocation } from "../../graphql/queries";

interface Location {
  id: string;
  author: string;
  name: string;
  address: string;
  description: string;
  image: string;
}

interface LocationsState {
  status: "idle" | "loading" | "failed"; // TODO: do I need that and why?
  locationDetails: { [key: string]: Location };
  locations: string[];
}

export interface CheckIn {
  location: string;
  createdAt: string;
}

const initialState: LocationsState = {
  status: "idle",
  locationDetails: {},
  locations: [],
};

export const getLocationDetails = createAsyncThunk("locations/getLocationDetails", async (locationId: string) => {
  const userData = await Auth.currentAuthenticatedUser().catch(() => false);

  if (userData?.username) {
    const result = await API.graphql(graphqlOperation(queryLocation, { locationId: `LOC#${locationId}` }));
    if ("data" in result && !("errors" in result)) {
      return (result.data as any)?.queryLocation[0];
    }
  }

  const result = await (await fetch(`${awsExports.aws_api_gateway_endpoint}/locations/${locationId}`)).json();
  return result;
});

export const getLocationPdfBytes = createAsyncThunk("locations/getLocationPdf", async (name, url) => {
  const result = await API.graphql(graphqlOperation(getLocationPdf, { input: { name, url } }));

  if ("data" in result && !("errors" in result)) {
    return (result.data as any)?.getLocationPdf;
  }
});

export const getLocations = createAsyncThunk("locations/getLocations", async () => {
  const result = await API.graphql(graphqlOperation(queryByType, { input: queryType.location }));

  if ("data" in result && !("errors" in result)) {
    return (result.data as any)?.queryByType;
  }
});

export const checkIn = createAsyncThunk("locations/checkin", async (locationiId: string) => {
  const result = await API.graphql(graphqlOperation(addCheckin, { location: locationiId }));
  return (result as any).data?.addCheckin;
});

export const locationsSLice = createSlice({
  name: "locations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLocations.fulfilled, (state, action) => {
        state.status = "idle";
        state.locations = action.payload;
      })
      .addCase(getLocationDetails.fulfilled, (state, action) => {
        state.status = "idle";
        state.locationDetails[action.payload?.id?.replace("LOC#", "")] = action.payload;
      })
      .addCase(getLocationPdfBytes.fulfilled, (state, action) => {
        state.status = "idle";
      });
  },
});

export default locationsSLice.reducer;
