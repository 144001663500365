import { LinearProgress } from "@mui/material";
import { API, graphqlOperation } from "aws-amplify";
import axios, { AxiosResponse } from "axios";
import * as qrcode from "qrcode";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { GetUploadInfoQuery } from "./API";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { RootState } from "./app/store";
import { imageBaseUrl } from "./Checkins";
import { open } from "./features/alertSlice/alertSlice";
import { getLocations } from "./features/locationsSlice/locationsSlice";
import { addLocation, updateLocation } from "./graphql/mutations";
import { getLocationPdf, getUploadInfo } from "./graphql/queries";

const printQrOnCanvas = (
  checkinUrl: string | undefined,
  canvasElement: any
) => {
  if (checkinUrl && canvasElement) {
    qrcode.toCanvas(
      canvasElement,
      `${checkinUrl}/checkin` ?? "",
      (error: any) => {
        if (error) console.error(error);
      }
    );
  }
};

const getLoacationUrl = (id: string | undefined) => {
  return id && `https://${process.env.REACT_APP_URL}/lokale/${id.substr(4)}`;
};

const getUploadInfos = async () => {
  const result = await API.graphql<GetUploadInfoQuery>(
    graphqlOperation(getUploadInfo)
  );

  return (result as any).data.getUploadInfo;
};

const uploadImageToBackBlaze = async (
  uploadInfo: { uploadUrl: string; authorizationToken: string },
  file: any
): Promise<AxiosResponse> => {
  return axios.post(uploadInfo.uploadUrl, file, {
    headers: {
      Authorization: uploadInfo.authorizationToken,
      "Content-Type": "b2/x-auto",
      "X-Bz-File-Name": `${"image"}_${new Date().toISOString()}.jpg`,
      "X-Bz-Content-Sha1": "do_not_verify",
    },
  });
};

function AdminAddLocation2() {
  const [url, setUrl] = useState("" as string | undefined);
  const [name, setName] = useState("" as string | undefined);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [imageId, setImageId] = useState("");
  const [uploading, setUploading] = useState(false);

  const dispatch = useAppDispatch();
  const locations = useAppSelector(
    (state: RootState) => state.locations.locations
  );

  React.useEffect(() => {
    dispatch(getLocations());
  }, [dispatch]);

  React.useEffect(() => {
    printQrOnCanvas(url, canvasElement);
  }, [url]);

  const canvasRef = React.useRef(null as any);
  const canvasElement = canvasRef.current;

  const saveLocation = async (data: any) => {
    try {
      setName(data.name);

      const location = await API.graphql(
        graphqlOperation(data.id ? updateLocation : addLocation, {
          input: {
            id: data.id,
            name: data.name,
            address: data.address,
            description: data.description,
            image: imageId,
            latitude: data.latitude,
            longitude: data.longitude,
            published: data.published === "true",
            hide: data.hide === "true",
            newUntil: data.newUntil,
            bierquest: !!data.bierquest
          },
        })
      );

      const action = data.id ? "updateLocation" : "addLocation";

      const checkinUrl = getLoacationUrl((location as any)?.data?.[action]?.id);

      setUrl(checkinUrl || "");

      dispatch(
        open({ type: "success", message: `${data?.name} gespeichert!` })
      );
    } catch (error: unknown) {
      console.error(error);
      dispatch(open({ type: "error", message: `${(error as Error).message}` }));
    }
  };

  const getPdf = async () => {
    dispatch(open({ type: "info", message: `Generiere PDF...` }));

    const r = await API.graphql(
      graphqlOperation(getLocationPdf, {
        input: {
          name,
          url: `${url}/checkin`,
        },
      })
    );

    const pdf = (r as any)?.data?.getLocationPdf;

    dispatch(open({ type: "info", message: <a href={pdf} download={name}>PDF für {name} herunterladen.</a> }));
  };

  const uploadImage = async (
    file: any,
    callback: (imageUploadResponse: any) => any
  ) => {
    setUploading(true);
    const uploadInfo = await getUploadInfos();

    const imageUploadResponse = await uploadImageToBackBlaze(uploadInfo, file);

    callback(imageUploadResponse);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({});

  const onUpload = (imageUploadResponse: any) => {
    console.log({ imageUploadResponse });

    setValue("imageId", imageUploadResponse.data.fileId);
    setValue("image", imageUploadResponse.data.fileId);
    setImageId(imageUploadResponse.data.fileId);
    dispatch(open({ type: "success", message: `Abbildung hochgeladen!` }));
    setUploading(false);
  };

  useEffect(() => {
    if (!selectedLocation) return;
    const location = (locations as any[])?.find(
      (location: any) => location?.id === selectedLocation
    );

    setUrl(getLoacationUrl(location?.id));
    setName(location?.name);

    location &&
      Object.keys(location).forEach((key) => {
        setValue(key, location[key]);
        if (typeof location[key] === "boolean" && location[key] === true) {
          setValue(key, "true");
        } else if (
          typeof location[key] === "boolean" &&
          location[key] === false
        ) {
          setValue(key, "false");
        }
        setValue("imageId", location["image"]);
        setValue("image", location["image"]);
        setImageId(location.image);
      });
  }, [selectedLocation, setValue]);

  return (
    <div className="Admin">
      <h2>Lokal editieren/hinzufügen</h2>

      <form>
        <label>
          Lokal zum editieren auswählen oder neues anlegen:
          <select onChange={(e) => setSelectedLocation(e.target.value)}>
            <option defaultChecked value={""}>
              -- Neues Lokal --
            </option>
            {locations.map((location: any) => (
              <option value={location.id} key={location.id}>
                {location.name} ({location.address})
              </option>
            ))}
          </select>
        </label>
      </form>
      <br />

      <form onSubmit={handleSubmit(saveLocation)}>
        {imageId && !uploading && (
          <div className="center">
            <img
              className="preview"
              width={200}
              src={`${imageBaseUrl}${imageId}`}
            />
          </div>
        )}
        <label>
          Name:
          <input
            placeholder="e.g. Hopfengarten"
            type="text"
            className="locationName"
            {...register("name", { required: true })}
          />
        </label>
        <br />
        <label>
          Adresse:
          <input
            placeholder="e.g. Hauptstr. 1, 38111 Braunschweig"
            type="text"
            {...register("address", { required: true })}
          />
        </label>
        <br />
        <label>
          Abbildung:
          <input
            type="file"
            accept="image/*"
            onChange={(event) => {
              uploadImage(event?.target?.files?.[0], onUpload);
            }}
          />
        </label>
        {uploading && <LinearProgress />}
        <label>
          ImageId:
          <input
            placeholder="wird durch Upload in obigem Feld 'Abbildung' gesetzt"
            type="text"
            disabled={true}
            {...register("imageId", { required: true, disabled: true })}
            accept=".jpg, .jpeg"
          />
        </label>
        <br />
        <label>
          Latitude:
          <input
            placeholder="Bitte via Google Maps ermitteln"
            type="float"
            {...register("latitude", {
              required: true,
              min: 0,
              pattern: /^[+-]?\d+(\.\d+)?$/,
            })}
          />
        </label>
        <br />
        <label>
          Longitude:
          <input
            placeholder="Bitte via Google Maps ermitteln"
            type="float"
            {...register("longitude", {
              required: true,
              min: 0,
              pattern: /^[+-]?\d+(\.\d+)?$/,
            })}
          />
        </label>
        <br />
        <label>
          Beschreibung:
          <textarea
            placeholder="Ein bis zwei warme Sätze"
            rows={4}
            {...register("description", { required: true })}
          />
        </label>
        <br />
        <label>
          Neu bis:
          <input
            placeholder="e.g. 2029-12-31"
            type="text"
            className="newUntil"
            {...register("newUntil", { required: false })}
          />
        </label>
        <br />
        <label>
          <input
            {...register("published", { required: true })}
            type="radio"
            value={"true"}
          />
          Veröffentlicht
        </label>
        <label>
          <input
            {...register("published", { required: true })}
            type="radio"
            value={"false"}
          />
          Unveröffentlicht
        </label>
        <br />
        <label>
          <input
            {...register("hide", { required: true })}
            type="radio"
            value={"true"}
          />
          Versteckt
        </label>
        <label>
          <input
            {...register("hide", { required: true })}
            type="radio"
            value={"false"}
          />
          Sichtbar
        </label>
        <br />
        <br />
        <label>
          <input
            {...register("bierquest")}
            type="checkbox"
          />
          Bierquest-Location
        </label>
        <br />
        {Object.keys(errors).length != 0 ? (
          <>
            <h4>Fehler in den Feldern:</h4>
            <ul>
              {Object.entries(errors).map(([key, value]: any[]) => (
                <li>{key}</li>
              ))}
            </ul>
          </>
        ) : (
          ""
        )}
        <input
          type="submit"
          value={
            selectedLocation
              ? `Lokal mit ID "${selectedLocation.substring(4, 99)}" updaten`
              : `Lokal anlegen`
          }
          disabled={isSubmitting || Object.keys(errors).length != 0}
        />
      </form>

      <div className="center">
        <canvas ref={canvasRef}></canvas>
        {url && name && (
          <p>
            <a href={url}>{url}</a>
            <br />
            <br />
            <input
              type="submit"
              onClick={getPdf}
              value={`Lokal-PDF für ${name} generieren`}
            />
          </p>
        )}
      </div>
    </div>
  );
}

export default AdminAddLocation2;
