import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { getUserDetails } from "../features/userSlice/userSlice";
import { FriendMgmtButton } from "./FriendMgmtButton";
import * as style from "@dicebear/pixel-art";
import { createAvatar } from "@dicebear/avatars";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import LinearProgress from "@mui/material/LinearProgress";
import { imageBaseUrl } from "../Checkins";

export function UserDetails() {
  const params = useParams();

  return <UserDetailsView user={params.user as string} />;
}

function UserDetailsView({ user }: { user: string }) {
  const dispatch = useAppDispatch();
  // const [avatar, setAvatar] = useState("");
  const userDetails = useAppSelector(
    (state: RootState) => state.user.users[user]
  );

  useEffect(() => {
    dispatch(getUserDetails(user));
  }, [user, dispatch]);

  // useEffect(() => {
  //   if (userDetails?.username && !avatar) {
  //     const svg = createAvatar(style, {
  //       seed: userDetails?.username,
  //       backgroundColor: "#099ac8",
  //       scale: 100,
  //     });

  //     setAvatar(`data:image/svg+xml;base64,${btoa(svg as unknown as string)}`);
  //   }
  // }, [userDetails?.username, avatar]);

  if (!userDetails?.username) {
    return <LinearProgress />;
  }

  return (
    <Box
      sx={{ mx: "auto", p: 1, textAlign: "center", justifyContent: "center" }}
    >
      <Box sx={{ mx: "auto", textAlign: "center", justifyContent: "center" }}>
        <Typography sx={{ m: 2, flex: 1 }} variant="h4" component="div">
          {userDetails?.username}
        </Typography>
        <FriendMgmtButton friend={userDetails?.username as string} />
      </Box>
      <Box
        sx={{
          mx: "auto",
          mt: "20px",
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Avatar
          src={`${imageBaseUrl}${(userDetails as any)?.avatar}`}
          alt={userDetails?.username}
          sx={{ width: 200, height: 200 }}
        />
      </Box>
      <p>
        registered since{" "}
        {DateTime.fromISO(userDetails?.registeredAt || "")
          .setLocale("de-DE")
          .toLocaleString({ month: "long", day: "numeric", year: "numeric" })}
      </p>
    </Box>
  );
}
