import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "../App";
import { RequireAdmin, RequireAuth } from "../Auth/Auth";
import { CheckInScreen } from "../Checkin";
import { About } from "../Content/About";
import { Impressum } from "../Content/Impressum";
import { Datenschutz } from "../Content/Privacy";
import { HomePage } from "../HomePage";
import Locations from "../Locations";
import NoMatch from "../NoMatch";
import { usersPath } from "../User/UserList";
import Users from "../User/Users";
import AdminAddLocation2 from "../AdminAddLocation2";
const AdminAddLocation = React.lazy(() => import("../AdminAddLocation"));

export function Routing() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path="/login" element={<HomePage />} />
        <Route
          path={`${usersPath}/*`}
          element={
            <RequireAuth>
              <Users />
            </RequireAuth>
          }
        />
        <Route path="lokale/*" element={<Locations />} />
        <Route
          path="admin"
          element={
            <RequireAdmin>
              <Suspense fallback={<span>Loading...</span>}>
                <AdminAddLocation />
              </Suspense>
            </RequireAdmin>
          }
        />        
        <Route
          path="cmo"
          element={
            <RequireAdmin>
              <Suspense fallback={<span>Loading...</span>}>
                <AdminAddLocation2 />
              </Suspense>
            </RequireAdmin>
          }
        />
        <Route
          path="checkin"
          element={
            <RequireAuth>
              <CheckInScreen />
            </RequireAuth>
          }
        />{" "}
        <Route path="info" element={<About />} />
        <Route path="impressum" element={<Impressum />} />
        <Route path="datenschutz" element={<Datenschutz />} />
        <Route path="lokale" element={<Locations />} />
        <Route
          path="*"
          element={
            <RequireAuth>
              <NoMatch />
            </RequireAuth>
          }
        />
      </Route>
    </Routes>
  );
}
