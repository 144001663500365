import { DateTime } from "luxon";
import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { CheckIn } from "./features/locationsSlice/locationsSlice";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "./app/hooks";
import { RootState } from "./app/store";
import { location } from "./API";

export const imageBaseUrl =
  "https://images.bierfiebel.de/b2api/v1/b2_download_file_by_id?fileId=";

export default function AlignItemsList({
  checkins,
}: {
  checkins: { [key: string]: CheckIn[] };
}) {
  const navigate = useNavigate();
  const locations = useAppSelector(
    (state: RootState) => state.locations.locations
  );

  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {Object.keys(checkins).map((locationId) => {

        const { createdAt } = checkins[locationId][0];

        const location: location | undefined = (
          locations as unknown as location[]
        )?.find((location: any) => location.id === `LOC#${locationId}`);

        const locationName = location?.name;

        return (
          <div key={locationName}>
            <ListItem
              alignItems="flex-start"
              onClick={() => navigate(`/lokale/${locationId}`)}
              className="pointer"
            >
              <ListItemAvatar>
                <Avatar
                  alt={locationName}
                  src={`${imageBaseUrl}${location?.image}`}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`✓ ${locationName}`}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      <span>
                        am{" "}
                        {DateTime.fromISO(createdAt || "")
                          .setLocale("de-DE")
                          .toLocaleString({
                            month: "long",
                            day: "numeric",
                            year: "numeric",
                          })}{" "}
                        besucht.
                        {checkins[locationId].length > 1 &&
                          `Sowie ${
                            checkins[locationId].length - 1
                          } weitere Male zuvor.`}
                      </span>
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </div>
        );
      })}
    </List>
  );
}
