import { Avatar, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";

function Team() {
  const people = ["Wiki", "B1BChris", "Frank", "idleon", "Stefan", "r0bs"].map(
    (user) => (
      <Avatar
        src={`https://images.bierfiebel.de/file/biernadel-images/${user}_avatar.jpg`}
      ></Avatar>
    )
  );

  return (
    <Stack direction="row" spacing={2} my={1}>
      {people}
    </Stack>
  );
}

export function About() {
  return (
    <Box sx={{ mt: 3, p: 1, textAlign: "left" }}>
      <Typography variant="h4" component="h2">
        Braunschweiger Biernadel
      </Typography>

      <Box sx={{ mt: 3, p: 1, textAlign: "center" }}>
        <img src="logo.png" width="200px" alt="Biernadel" />
      </Box>
      <br />
      <Typography>
        Inspiriert von der Harzer Wandernadel, bietet die Braunschweiger
        Biernadel-App die Möglichkeit, Besuche in ausgewählten Lokalen in
        Braunschweig zu dokumentieren.
      </Typography>
      <Typography>
        Die Biernadel-App ist der ideale Begleiter bei der Erkundung
        Braunschweiger Lokale. Unser Kuratorium trifft eine unabhängige Auswahl
        besonderer und sehenswürdiger Lokale und erweitert diese stetig.
      </Typography>
      <Typography>
        Künftig werden neben dem Besuchsverlauf verschiedene Abzeichen etabliert
        und Sonderstempel eingeführt.
      </Typography>

      <Typography variant="h5" component="h3" sx={{ pt: 1, mt: 3 }}>
        Funktionsweise
      </Typography>

      <Typography>
        In teilnehmenden Lokalen wird ein QR-Code mittels der App über die
        Kamera des Smartphones eingelesen. Daraufhin wird der Besuch des Lokals
        im Profil des Nutzers gespeichert. Der Nutzer kann entscheiden, ob seine
        Freunde per Push-Nachricht über den Besuch informiert werden sollen.
      </Typography>

      <Typography variant="h5" component="h3" sx={{ pt: 1, mt: 3 }}>
        Team
      </Typography>

      <Team />

      <Typography>
        Die Idee für die Biernadel hatten wir im Sommer 2021 bei einem
        gemeinsamen Kneipenbesuch. Seit betreiben und erweitern wir die
        Biernadel in unserer Freizeit. Das Biernadel-Team (Wiebke, Christian,
        Frank, Sergey, Stefan und Robert) freut sich immer auf die Biernadel
        angesprochen zu werden!
      </Typography>

      <Typography variant="h5" component="h3" sx={{ pt: 1, mt: 3 }}>
        Roadmap
      </Typography>

      <Typography>
        Demnächst soll der Funktionsumfang der App erweitert und auf die
        Bedürfnisse der Nutzer angepasst werden.
      </Typography>

      <Typography>
        Bist du an weiteren Funktionen interessiert, die diese App bereichern
        könnten? Wir freuen uns über eine E-Mail an{" "}
        <a href="mailto:biernadel@gmail.com?subject=Biernadel Feature Request">
          biernadel@gmail.com
        </a>
        .
      </Typography>

      <Typography variant="h5" component="h3" sx={{ pt: 1, mt: 3 }}>
        Rechtliches
      </Typography>

      <Typography>
        <ul>
          <li>
            <a href="/datenschutz" className="link">
              Datenschutz
            </a>
          </li>
          <li>
            <a href="/impressum" className="link">
              Impressum
            </a>
          </li>
        </ul>
      </Typography>
    </Box>
  );
}
