import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import AlignItemsList, { imageBaseUrl } from "../Checkins";
import {
  CheckIn,
  getLocations
} from "../features/locationsSlice/locationsSlice";
import { getMeDetails } from "../features/userSlice/userSlice";
import FullScreenDialog from "../FullScreenDialog";
import Score from "../Score";
import IconMenu from "./UserPageMenu";
import { groupBy, isCheckIns } from "./Users";

export function MeDetails() {
  const [checkins, setCheckins] = useState({} as { [key: string]: CheckIn[] });
  const dispatch = useAppDispatch();
  const meDetails = useAppSelector((state: RootState) => state.user.me);
  const locations = useAppSelector(
    (state: RootState) => state.locations.locations
  );
  const checkinLocationsCount = Object.keys(
    groupBy<CheckIn>(meDetails.checkIns ?? [], "location")
  ).length;

  useEffect(() => {
    dispatch(getMeDetails());
    dispatch(getLocations());
  }, [dispatch]);

  useEffect(() => {
    if (isCheckIns(meDetails.checkIns)) {
      const checkins = groupBy<CheckIn>(meDetails.checkIns, "location");
      setCheckins(checkins);
    }
  }, [meDetails.checkIns, dispatch]);

  // useEffect(() => {
  //   if (meDetails.username && !avatar) {
  //     const svg = createAvatar(style, {
  //       seed: meDetails.username,
  //       backgroundColor: "#099ac8",
  //       scale: 100,
  //     });

  //     setAvatar(`data:image/svg+xml;base64,${btoa(svg as unknown as string)}`);
  //   }
  // }, [meDetails.username, avatar]);


  return (
    <>
      <FullScreenDialog goToOnClose="/">
        <Box sx={{ mx: "auto", p: 1 }}>
          <Box
            sx={{
              mx: "auto",
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Avatar
              src={`${imageBaseUrl}${(meDetails as any).avatar}`}
              alt={meDetails?.username}
              sx={{ width: 200, height: 200 }}
            />
          </Box>
          <Box
            sx={{
              mx: "auto",
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ m: 2, flex: 1 }} variant="h4" component="div">
              {meDetails?.username}
            </Typography>
            <Typography
              sx={{ m: 2, mt: -2, flex: 2 }}
              variant="h6"
              component="div"
            >
              {meDetails?.email}
            </Typography>
          </Box>
          <Score
            locationsTotal={locations?.length}
            locationsVisitedCount={checkinLocationsCount}
          />
          <AlignItemsList checkins={checkins ?? []} />
        </Box>
        <IconMenu />
      </FullScreenDialog>
    </>
  );
}
