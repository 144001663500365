import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";

import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";

import { createAvatar } from "@dicebear/avatars";
import * as style from "@dicebear/pixel-art";
import { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import DrawerMenu from "./DrawerMenu";

export default function ReducedMenuAppBar() {
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState("");
  const meDetails = useAppSelector((state: RootState) => state.user.me);

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => setOpen(!open);

  useEffect(() => {
    if (meDetails.username && !avatar) {
      let svg = createAvatar(style, {
        seed: meDetails.username,
        backgroundColor: "#099ac8",
        scale: 90,
      });

      setAvatar(`data:image/svg+xml;base64,${btoa(svg)}`);
    }
  }, [meDetails.username, avatar]);

  return (
    <>
      {/* <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static"> */}
          {/* <Toolbar> */}
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ m: 2, position: "absolute", backgroundColor: "#ffca28" }}
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}> */}
              {/* Biernadel */}
            {/* </Typography> */}

            {/* <div>
              {meDetails.username ? (
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  onClick={() => navigate(`${usersPath}/me`)}
                  color="inherit"
                >
                  <Avatar
                    src={meDetails.username && avatar}
                    alt={meDetails?.username}
                    sx={{ width: 28, height: 28 }}
                  />
                </IconButton>
              ) : (
                <IconButton
                  size="large"
                  aria-label="go to home page"
                  onClick={() => navigate(`/`)}
                  color="inherit"
                >
                  <img
                    src="/logo192.png"
                    alt="Bier"
                    width="28"
                    height="28"
                    // sx={{ width: 28, height: 28 }}
                  />
                </IconButton>
              )}
            </div> */}
          {/* </Toolbar> */}
        {/* </AppBar>
      </Box> */}
      <Drawer anchor="left" open={open} onClose={toggleDrawer}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
          <DrawerMenu />
        </Box>
      </Drawer>
    </>
  );
}
