/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addLocation = /* GraphQL */ `
  mutation AddLocation($input: locationInput!) {
    addLocation(input: $input) {
      id
      name
      address
      description
      image
      author
      createdAt
      checkins
      published
      latitude
      longitude
      bierquest
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation($input: locationInput!) {
    updateLocation(input: $input) {
      id
      name
      address
      description
      image
      author
      createdAt
      checkins
      published
      latitude
      longitude
      bierquest
    }
  }
`;
export const addCheckin = /* GraphQL */ `
  mutation AddCheckin($location: String!) {
    addCheckin(location: $location) {
      location
      createdAt
    }
  }
`;
export const addCheckinAndNotify = /* GraphQL */ `
  mutation AddCheckinAndNotify($location: String!) {
    addCheckinAndNotify(location: $location) {
      location
      createdAt
    }
  }
`;
export const beerShout = /* GraphQL */ `
  mutation BeerShout($location: String!) {
    beerShout(location: $location)
  }
`;
export const sendMessage = /* GraphQL */ `
  mutation SendMessage($input: messageInput!) {
    sendMessage(input: $input)
  }
`;
export const addFriendRequest = /* GraphQL */ `
  mutation AddFriendRequest($friend: String!) {
    addFriendRequest(friend: $friend) {
      friendName
      createdAt
      requestTo
      requestFrom
    }
  }
`;
export const removeFriend = /* GraphQL */ `
  mutation RemoveFriend($friend: String!) {
    removeFriend(friend: $friend) {
      friendName
      createdAt
      requestTo
      requestFrom
    }
  }
`;
export const addFriendRequestAndNotify = /* GraphQL */ `
  mutation AddFriendRequestAndNotify($friend: String!) {
    addFriendRequestAndNotify(friend: $friend) {
      friendName
      createdAt
      requestTo
      requestFrom
    }
  }
`;
export const acceptFriendRequest = /* GraphQL */ `
  mutation AcceptFriendRequest($friend: String!) {
    acceptFriendRequest(friend: $friend) {
      friendName
      acceptedAt
    }
  }
`;
export const acceptFriendRequestAndNotify = /* GraphQL */ `
  mutation AcceptFriendRequestAndNotify($friend: String!) {
    acceptFriendRequestAndNotify(friend: $friend) {
      friendName
      acceptedAt
    }
  }
`;
export const editMe = /* GraphQL */ `
  mutation EditMe($input: keyAndValue) {
    editMe(input: $input) {
      id
      username
      updatedAt
      registeredAt
      lastLogin
      email
      avatar
      pushToken
    }
  }
`;
