import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";

export function Datenschutz() {
  return (
    <Box sx={{ mt: 3, p: 1, textAlign: "left" }}>
      <Typography variant="h4" component="h2">
       Datenschutz
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Diese Seite speichert nur für die Bereitstellung der Funktionalität
        benötigte Daten.
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Um virtuelle Stempel für die Braunschweiger Biernadel zu sammeln, ist
        eine Anmeldung mit einem persönlichen Account erforderlich. Die
        Anmeldung kann anonym mit einem beliebigen Nutzernamen und einer
        E-Mail-Adresse erfolgen.
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Die Angabe einer E-Mail-Adresse ist notwendig um Nutzer von
        verschiedenen Endgeräten zugreifen lassen zu können und das Passwort im
        Falle eines Verlusts zurückzusetzen (dabei wird ein Code zur Bestätigung
        an die hinterlegte E-Mail-Adresse gesendet). Durch die Verwendung von
        einer anonymen E-Mail-Adresse (z.B. durch Apples
        Hide-My-Email-Funktionalität) können Nutzer komplett ohne Angabe
        personenbezogener Daten die Anwendung nutzen.
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Auf den Server übertragene Daten (Nutzername, E-Mail-Adresse, Check-Ins,
        Freunde und Anfragen) werden verschlüsselt übertragen und gespeichert.
        Beim Scannen eines QR-Codes werden keine Aufzeichnungen vorgenommen. Das
        Endgerät erkennt den Inhalt des QR-Codes selbstständig und überträgt nur
        die Prüfziffer aus dem QR-Code um ein Check-In auf dem Server für den
        Nutzer zu speichern.
      </Typography><br /><br /><br />
      <Typography variant="h5" component="h2">
        Allgemeines zur Datenverarbeitung
      </Typography><br />
      <Typography variant="h6" component="h2">
        1. Umfang der Verarbeitung personenbezogener Daten{" "}
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich nur,
        soweit dies zur Bereitstellung einer funktionsfähigen Website sowie
        unserer Inhalte und Leistungen erforderlich ist. Die Verarbeitung
        personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach
        Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen
        eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen
        nicht möglich ist und die Verarbeitung der Daten durch gesetzliche
        Vorschriften gestattet ist.
      </Typography><br />
      <Typography variant="h6" component="h2">
        2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten{" "}
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine
        Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 lit. a
        EU-Datenschutzgrundverordnung (DSGVO) als Rechtsgrundlage. Bei der
        Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines
        Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich
        ist, dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt
        auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher
        Maßnahmen erforderlich sind. Soweit eine Verarbeitung personenbezogener
        Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist,
        der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als
        Rechtsgrundlage. Für den Fall, dass lebenswichtige Interessen der
        betroffenen Person oder einer anderen natürlichen Person eine
        Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6
        Abs. 1 lit. d DSGVO als Rechtsgrundlage. Ist die Verarbeitung zur
        Wahrung eines berechtigten Interesses unseres Unternehmens oder eines
        Dritten erforderlich und überwiegen die Interessen, Grundrechte und
        Grundfreiheiten des Betroffenen das erstgenannte Interesse nicht, so
        dient Art. 6 Abs. 1 lit. f DSGVO als Rechtsgrundlage für die
        Verarbeitung.
      </Typography><br />
      <Typography variant="h6" component="h2">
        3. Datenlöschung und Speicherdauer
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Die personenbezogenen Daten der betroffenen Person werden gelöscht oder
        gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung
        kann darüber hinaus erfolgen, wenn dies durch den europäischen oder
        nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder
        sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen
        wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn
        eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft,
        es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der
        Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
      </Typography><br />
      <Typography variant="h5" component="h2">
        Bereitstellung der Website und der App und Erstellung von Logfiles
      </Typography><br />
      <Typography variant="h6" component="h2">
        1. Umfang der Verarbeitung personenbezogener Daten{" "}
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Bei jedem Aufruf unserer Internetseite erfasst unser System
        automatisiert Daten und Informationen vom Computersystem des aufrufenden
        Rechners. Folgende Daten werden hierbei erhoben: (1) Informationen über
        den Browsertyp und die verwendete Version (2) Das Betriebssystem des
        Nutzers (3) Den Internet-Service-Provider des Nutzers (4) Die IP-Adresse
        des Nutzers (5) Datum und Uhrzeit des Zugriffs.
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert.
        Nicht hiervon betroffen sind die IP-Adressen des Nutzers oder andere
        Daten, die die Zuordnung der Daten zu einem Nutzer ermöglichen. Eine
        Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten
        des Nutzers findet nicht statt.
      </Typography><br />
      <Typography variant="h6" component="h2">
        2. Rechtsgrundlage für die Datenverarbeitung
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Rechtsgrundlage für die vorübergehende Speicherung der Daten ist Art. 6
        Abs. 1 lit. f DSGVO.
      </Typography><br />
      <Typography variant="h6" component="h2">
        3. Zweck der Datenverarbeitung
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Die vorübergehende Speicherung der IP-Adresse durch das System ist
        notwendig, um eine Auslieferung der Website an den Rechner des Nutzers
        zu ermöglichen. Hierfür muss die IP- Adresse des Nutzers für die Dauer
        der Sitzung gespeichert bleiben.
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        In diesen Zwecken liegt auch unser berechtigtes Interesse an der
        Datenverarbeitung nach Art. 6 Abs. 1 lit. f DSGVO.
      </Typography><br />
      <Typography variant="h6" component="h2">
        4. Dauer der Speicherung
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
        ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der
        Daten zur Bereitstellung der Website ist dies der Fall, wenn die
        jeweilige Sitzung beendet ist.
      </Typography><br />
      <Typography variant="h6" component="h2">
        5. Widerspruchs- und Beseitigungsmöglichkeit
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Die Erfassung der Daten zur Bereitstellung der Website und die
        Speicherung der Daten in Logfiles ist für den Betrieb der Internetseite
        zwingend erforderlich. Es besteht folglich seitens des Nutzers keine
        Widerspruchsmöglichkeit.
      </Typography><br />
      <Typography variant="h5" component="h2">
        Verwendung von Cookies
      </Typography><br />
      <Typography variant="h6" component="h2">
        a) Beschreibung und Umfang der Datenverarbeitung
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Unsere Webseite und App verwenden Cookies. Bei Cookies handelt es sich
        um Textdateien, die im Internetbrowser bzw. vom Internetbrowser auf dem
        Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine
        Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers
        gespeichert werden. Dieser Cookie enthält eine charakteristische
        Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim
        erneuten Aufrufen der Website ermöglicht.
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu
        gestalten. Einige Elemente unserer Internetseite erfordern es, dass der
        aufrufende Browser auch nach einem Seitenwechsel identifiziert werden
        kann. In den Cookies werden dabei folgende Daten gespeichert und
        übermittelt: Log-In-Informationen
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Somit handelt es sich ausschließlich um technisch notwendige Cookies.
      </Typography><br />
      <Typography variant="h6" component="h2">
        b) Rechtsgrundlage für die Datenverarbeitung
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter
        Verwendung von Cookies ist Art. 6 Abs. 1 lit. f DSGVO.
      </Typography><br />
      <Typography variant="h6" component="h2">
        c) Zweck der Datenverarbeitung
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung
        von Websites für die Nutzer zu vereinfachen. Einige Funktionen unserer
        Internetseite können ohne den Einsatz von Cookies nicht angeboten
        werden. Für diese ist es erforderlich, dass der Browser auch nach einem
        Seitenwechsel wiedererkannt wird. In den Cookies werden dabei folgende
        Daten gespeichert und übermittelt: Log-In-Informationen
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden
        nicht zur Erstellung von werblich genutzten Nutzerprofilen verwendet.
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        In diesen Zwecken liegt auch unser berechtigtes Interesse in der
        Verarbeitung der personenbezogenen Daten nach Art. 6 Abs. 1 lit. f
        DSGVO.
      </Typography><br />
      <Typography variant="h6" component="h2">
        d) Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an
        unserer Seite übermittelt. Daher haben Sie als Nutzer auch die volle
        Kontrolle über die Verwendung von 11 Cookies. Durch eine Änderung der
        Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von
        Cookies deaktivieren oder einschränken. Bereits gespeicherte Cookies
        können jederzeit gelöscht werden. Dies kann auch automatisiert erfolgen.
        Werden Cookies für unsere Website deaktiviert, können möglicherweise
        nicht mehr alle Funktionen der Website vollumfänglich genutzt werden.
      </Typography><br />
      <Typography variant="h5" component="h2">
        Registrierung
      </Typography><br />
      <Typography variant="h6" component="h2">
        1. Beschreibung und Umfang der Datenverarbeitung
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Auf unserer Internetseite/App bieten wir Nutzern die Möglichkeit, sich
        unter Angabe personenbezogener Daten zu registrieren. Die Daten werden
        dabei in eine Eingabemaske eingegeben und an uns übermittelt und
        gespeichert. Eine Weitergabe der Daten an Dritte findet nicht statt.
        Folgende Daten werden im Rahmen des Registrierungsprozesses erhoben: (1)
        Die IP-Adresse des Nutzers (2) Datum und Uhrzeit der Registrierung
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Im Rahmen des Registrierungsprozesses wird eine Einwilligung des Nutzers
        zur Verarbeitung dieser Daten eingeholt
      </Typography><br />
      <Typography variant="h6" component="h2">
        2. Rechtsgrundlage für die Datenverarbeitung{" "}
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer
        Einwilligung des Nutzers Art. 6 Abs. 1 lit. a DSGVO.
      </Typography><br />
      <Typography variant="h6" component="h2">
        3. Zweck der Datenverarbeitung{" "}
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Eine Registrierung des Nutzers ist für das Bereithalten bestimmter
        Inhalte und Leistungen auf unserer Website erforderlich. Nämlich:
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Die Angabe einer E-Mail-Adresse ist notwendig um Nutzer von
        verschiedenen Endgeräten zugreifen lassen zu können und das Passwort im
        Falle eines Verlusts zurückzusetzen (dabei wird ein Code zur Bestätigung
        an die hinterlegte E-Mail-Adresse gesendet). Durch die Verwendung von
        einer anonymen E-Mail-Adresse (z.B. durch Apples
        Hide-My-Email-Funktionalität) können Nutzer komplett ohne Angabe
        personenbezogener Daten die Anwendung nutzen.
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Auf den Server übertragene Daten (Nutzername, E-Mail-Adresse, Check-Ins,
        Freunde und Anfragen) werden verschlüsselt übertragen und gespeichert.
        Beim Scannen eines QR-Codes werden keine Aufzeichnungen vorgenommen. Das
        Endgerät erkennt den Inhalt des QR-Codes selbstständig und überträgt nur
        die Prüfziffer aus dem QR-Code um ein Check-In auf dem Server für den
        Nutzer zu speichern.
      </Typography><br />
      <Typography variant="h6" component="h2">
        4. Beschreibung und Umfang der Datenverarbeitung
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
        ihrer Erhebung nicht mehr erforderlich sind. Dies ist für die während
        des Registrierungsvorgangs erhobenen Daten der Fall, wenn die
        Registrierung auf unserer Internetseite/App aufgehoben oder abgeändert
        wird.
      </Typography><br />
      <Typography variant="h6" component="h2">
        5. Widerspruchs- und Beseitigungsmöglichkeit{" "}
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Als Nutzer haben sie jederzeit die Möglichkeit, die Registrierung
        aufzulösen. Die über Sie gespeicherten Daten können Sie jederzeit
        abändern lassen.
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Wir behalten uns vor, Daten nach einer Zeit der Inaktivität automatisch
        zu löschen.
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Eine Löschung nehemen wir nach Aufforderung per E-Mail an
        biernadel@gmail.com vor.
      </Typography><br />
      <Typography variant="h5" component="h2">
        Rechte der betroffenen Person
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
        Betroffener i.S.d. DSGVO und es stehen Ihnen folgende Rechte gegenüber
        dem Verantwortlichen zu:
      </Typography><br />
      <Typography variant="h6" component="h2">
        1. Auskunftsrecht{" "}
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen,
        ob personenbezogene Daten, die Sie betreffen, von uns verarbeitet
        werden. Liegt eine solche Verarbeitung vor, können Sie von dem
        Verantwortlichen über folgende Informationen Auskunft verlangen: (1) die
        Zwecke, zu denen die personenbezogenen Daten verarbeitet werden; (2) die
        Kategorien von personenbezogenen Daten, welche verarbeitet werden; (3)
        die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die
        Sie betreffenden personenbezogenen Daten offengelegt wurden oder noch
        offengelegt werden; (4) die geplante Dauer der Speicherung der Sie
        betreffenden personenbezogenen Daten oder, falls konkrete Angaben hierzu
        nicht möglich sind, Kriterien für die Festlegung der Speicherdauer; (5)
        das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie
        betreffenden personenbezogenen Daten, eines Rechts auf Einschränkung der
        Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts
        gegen diese Verarbeitung; (6) das Bestehen eines Beschwerderechts bei
        einer Aufsichtsbehörde; (7) alle verfügbaren Informationen über die
        Herkunft der Daten, wenn die personenbezogenen Daten nicht bei der
        betroffenen Person erhoben werden; Stand Mai 2018 20 (8) das Bestehen
        einer automatisierten Entscheidungsfindung einschließlich Profiling
        gemäß Art.22 Abs.1 und 4 DSGVO und – zumindest in diesen Fällen –
        aussagekräftige Informationen über die involvierte Logik sowie die
        Tragweite und die angestrebten Auswirkungen einer derartigen
        Verarbeitung für die betroffene Person.{" "}
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie
        betreffenden personenbezogenen Daten in ein Drittland oder an eine
        internationale Organisation übermittelt werden. In diesem Zusammenhang
        können Sie verlangen, über die geeigneten Garantien gem. Art. 46 DSGVO
        im Zusammenhang mit der Übermittlung unterrichtet zu werden.
      </Typography><br />
      <Typography variant="h6" component="h2">
        3. Recht auf Einschränkung der Verarbeitung{" "}
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Unter den folgenden Voraussetzungen können Sie die Einschränkung der
        Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen: (1)
        wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine
        Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die
        Richtigkeit der personenbezogenen Daten zu überprüfen; 21 (2) die
        Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen
        Daten ablehnen und stattdessen die Einschränkung der Nutzung der
        personenbezogenen Daten verlangen; (3) der Verantwortliche die
        personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger
        benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen benötigen, oder (4) wenn Sie
        Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt
        haben und noch nicht feststeht, ob die berechtigten Gründe des
        Verantwortlichen gegenüber Ihren Gründen überwiegen.
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten
        eingeschränkt, dürfen diese Daten – von ihrer Speicherung abgesehen –
        nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer
        anderen natürlichen oder juristischen Person oder aus Gründen eines
        wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats
        verarbeitet werden.
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen
        eingeschränkt, werden Sie von dem Verantwortlichen unterrichtet bevor
        die Einschränkung aufgehoben wird.
      </Typography><br />
      <Typography variant="h6" component="h2">
        4. Recht auf Löschung{" "}
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Unter den folgenden Voraussetzungen können Sie die Einschränkung der
        Verarbeitung der Sie betreffenden personenbezogenen Daten verlangen: (1)
        wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine
        Dauer bestreiten, die es dem Verantwortlichen ermöglicht, die
        Richtigkeit der personenbezogenen Daten zu überprüfen; 21 (2) die
        Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen
        Daten ablehnen und stattdessen die Einschränkung der Nutzung der
        personenbezogenen Daten verlangen; (3) der Verantwortliche die
        personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger
        benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen benötigen, oder (4) wenn Sie
        Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt
        haben und noch nicht feststeht, ob die berechtigten Gründe des
        Verantwortlichen gegenüber Ihren Gründen überwiegen.
      </Typography><br />
      <Typography variant="h6" component="h2">
        5. Recht auf Unterrichtung
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der
        Verarbeitung gegenüber dem Verantwortlichen geltend gemacht, ist dieser
        verpflichtet, allen Empfängern, denen die Sie betreffenden
        personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
        Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen, es
        sei denn, dies erweist sich als unmöglich oder ist mit einem
        unverhältnismäßigen Aufwand verbunden. Ihnen steht gegenüber dem
        Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu
        werden.
      </Typography><br />
      <Typography variant="h6" component="h2">
        6. Recht auf Datenübertragbarkeit
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die
        Sie dem Verantwortlichen bereitgestellt haben, in einem strukturierten,
        gängigen und maschinenlesbaren Format zu erhalten. Außerdem haben Sie
        das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung
        durch den Verantwortlichen, dem die personenbezogenen Daten
        bereitgestellt wurden, zu übermitteln, sofern (1) die Verarbeitung auf
        einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2
        lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 lit. b DSGVO
        beruht und (2) die Verarbeitung mithilfe automatisierter Verfahren
        erfolgt.
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass
        die Sie betreffenden personenbezogenen Daten direkt von einem
        Verantwortlichen einem anderen Verantwortlichen übermittelt werden,
        soweit dies technisch machbar ist. Freiheiten und Rechte anderer
        Personen dürfen hierdurch nicht beeinträchtigt werden. Das Recht auf
        Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener
        Daten, die für die Wahrnehmung einer Aufgabe erforderlich ist, die im
        öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt
        erfolgt, die dem Verantwortlichen übertragen wurde.
      </Typography><br />
      <Typography variant="h6" component="h2">
        7. Widerspruchsrecht
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen
        Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden
        personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f
        DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
        Bestimmungen gestütztes Profiling. Der Verantwortliche verarbeitet die
        Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er
        kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die
        Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung
        dient der Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen. Werden die Sie betreffenden personenbezogenen Daten
        verarbeitet, um Direktwerbung zu betreiben, haben Sie das Recht,
        jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden
        personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies
        gilt auch für das Profiling, soweit es mit solcher Direktwerbung in
        Verbindung steht. Widersprechen Sie der Verarbeitung für Zwecke der
        Direktwerbung, so werden die Sie betreffenden personenbezogenen Daten
        nicht mehr für diese Zwecke verarbeitet. Sie haben die Möglichkeit, im
        Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft –
        ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels
        automatisierter Verfahren auszuüben, bei denen technische
        Spezifikationen verwendet werden. Bei Datenverarbeitung zu
        wissenschaftlichen, historischen oder statistischen Forschungszwecken:
        Sie haben auch das Recht, aus Gründen, die sich aus Ihrer besonderen
        Situation ergeben, bei der Verarbeitung Sie betreffender
        personenbezogener Daten, die zu wissenschaftlichen oder historischen
        Forschungszwecken oder zu statistischen Zwecken gem. Art. 89 Abs. 1
        DSGVO erfolgt, dieser zu widersprechen. Ihr Widerspruchsrecht kann
        insoweit beschränkt werden, als es voraussichtlich die Verwirklichung
        der Forschungs- oder Statistikzwecke unmöglich macht oder ernsthaft
        beeinträchtigt und die Beschränkung für die Erfüllung der Forschungs-
        oder Statistikzwecke notwendig ist.
      </Typography><br />
      <Typography variant="h6" component="h2">
        8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung
        jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die
        Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
        Verarbeitung nicht berührt.
      </Typography><br />
      <Typography variant="h6" component="h2">
        9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Sie haben das Recht, nicht einer ausschließlich auf einer
        automatisierten Verarbeitung – einschließlich Profiling – beruhenden
        Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche
        Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt.
        Dies gilt nicht, wenn die Entscheidung (1) für den Abschluss oder die
        Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen
        erforderlich ist, (2) aufgrund von Rechtsvorschriften der Union oder der
        Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und
        diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte
        und Freiheiten sowie Ihrer berechtigten Interessen enthalten oder (3)
        mit Ihrer ausdrücklichen Einwilligung erfolgt.
      </Typography><br />{" "}
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien
        personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht
        Art. 9 Abs. 2 lit. a oder g DSGVO gilt und angemessene Maßnahmen zum
        Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen
        getroffen wurden. Hinsichtlich der in (1) und (3) genannten Fälle trifft
        der Verantwortliche angemessene Maßnahmen, um die Rechte und Freiheiten
        sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht
        auf Erwirkung des Eingreifens einer Person seitens des Verantwortlichen,
        auf Darlegung des eigenen Standpunkts und auf Anfechtung der
        Entscheidung gehört.
      </Typography><br />
      <Typography variant="h6" component="h2">
        10. Recht auf Beschwerde bei einer Aufsichtsbehörde
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
        gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
        einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
        Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
        Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie
        betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
      </Typography><br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde,
        unterrichtet den Beschwerdeführer über den Stand und die Ergebnisse der
        Beschwerde einschließlich der Möglichkeit eines gerichtlichen
        Rechtsbehelfs nach Art. 78 DSGVO.
      </Typography><br />
    </Box>
  );
}
