import Box from "@mui/material/Box";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { MeDetails } from "./MeDetails";
import Typography from "@mui/material/Typography";
import { CheckIn } from "../features/locationsSlice/locationsSlice";
import { useAppSelector } from "../app/hooks";
import { UserList } from "./UserList";
import { UserSearch } from "./UserSearch";
import { RootState } from "../app/store";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { UserDetails } from "./UserDetails";

export const groupBy = <T extends { [key: string]: any }>(
  objectArray: T[],
  property: string
): { [key: string]: T[] } => {
  return objectArray.reduce((acc: { [key: string]: T[] }, obj: T) => {
    const key = obj[property];
    return { ...acc, [key]: [obj, ...(acc[key] ?? [])] };
  }, {});
};

export const isCheckIns = (arr: unknown): arr is CheckIn[] =>
  Array.isArray(arr) &&
  arr.every(
    (e: unknown) =>
      e && typeof e === "object" && "location" in e && "createdAt" in e
  );

function Users() {
  return (
    <Routes>
      <Route path="/" element={<FriendsOverview />} />
      <Route path=":user" element={<UserDetails />} />
      <Route path="/me" element={<MeDetails />} />
    </Routes>
  );
}

export default Users;

function FriendsOverview() {
  const meDetails = useAppSelector((state: RootState) => state.user.me);
  const [open, setOpen] = React.useState(true);

  return (
    <>
      <Box sx={{ m: "10px", mt: 3 }}>
        <Typography variant="h5">Search Users</Typography>

        <Box sx={{ mt: 2 }}>
          {open && (
            <Alert
              variant="outlined"
              severity="info"
              sx={{ mt: "10px", mb: "10px" }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              Make friends to receive updates on their future achievements!
            </Alert>
          )}
          <UserSearch />
        </Box>

        <Box sx={{ mt: 3 }}> </Box>
        {meDetails?.friends?.length ? (
          <>
            <Typography variant="h5">Your Friends</Typography>
            <UserList
              users={meDetails.friends.map((friend) => friend.friendName)}
              me={meDetails}
            />
          </>
        ) : (
          <></>
        )}
        {meDetails?.incomingFriendRequests?.length ? (
          <>
            <Typography variant="h5">
              {meDetails.incomingFriendRequests.length} Friend Requests
            </Typography>
            <UserList
              users={meDetails?.incomingFriendRequests.map(
                (friend) => friend.friendName
              )}
              me={meDetails}
            />
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}
