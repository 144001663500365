import React from "react";
import { Link } from "react-router-dom";

function NoMatch() {
  return (
    <div>
      <h2>404 - Es gibt kein Bier auf Hawaii</h2>
      <p>
        <Link to="/">Drum bleib ich hier (zur Homepage)</Link>
      </p>
    </div>
  );
}

export default NoMatch;
