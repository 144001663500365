import * as React from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PasswordIcon from "@mui/icons-material/Password";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Auth } from "aws-amplify";

const logout = async () => {
  const sure = window.confirm(
    "Sicher?"
  );
  if (!sure) return;
  await Auth.signOut();
  document.location.pathname = "/";
};

const deleteAccount = async () => {
  prompt(
    "Are you sure you want to delete your account? Type in your password to verify."
  );
};

export default function IconMenu() {
  return (
    <Paper sx={{ mt: 5, maxWidth: "100%" }}>
      <MenuList>
        {/* <MenuItem>
          <ListItemIcon>
            <ContentCut fontSize="small" />
          </ListItemIcon>
          <ListItemText>Cut</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘X
          </Typography>
        </MenuItem>
        <Divider /> */}
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Ausloggen</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() =>
            alert(
              "Bitte ausloggen und mit falschem Passwort einloggen um Zugriff auf die Zurücksetzen-Funktionalität zu erhalten."
            )
          }
        >
          <ListItemIcon>
            <PasswordIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Password ändern</ListItemText>
        </MenuItem>
        <Divider />
        {/* <MenuItem
          onClick={() =>
            alert(
              "Avatars are automatically generated with your username as seed. You can not change it at the moment."
            )
          }
        >
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText></ListItemText>
        </MenuItem> 
        <Divider /> */}
        <MenuItem
          onClick={() =>
            alert("Bitte nutze die App zum Löschen deines Accounts")
          }
        >
          {/*  onClick={deleteAccount}> */}
          <ListItemIcon>
            <DeleteForeverIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{ color: "red" }}>Account löschen</ListItemText>
        </MenuItem>
      </MenuList>
    </Paper>
  );
}
