import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./App.css";
import { useAppSelector } from "./app/hooks";
import { RootState } from "./app/store";

export const googlePlayStoreUrl =
  "https://play.google.com/store/apps/details?id=com.r0bs.BiernadelBS";
export const appleAppStoreUrl =
  "https://apps.apple.com/de/app/biernadel/id1626518042";

export function HomePage() {
  const me = useAppSelector((state: RootState) => state.user.loginMe);
  const navigate = useNavigate();
  const [value, setValue] = React.useState("login");

  // useEffect(() => {
  //   if (me.username) {
  //     navigate(`lokale`);
  //   }
  // });

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            // backgroundImage: `url(https://source.unsplash.com/${
            //   window.innerWidth
            // }x${(window.innerWidth / 16) * 9}/?beer)`,
            backgroundImage: "url(photo-1571613316887-6f8d5cbf7ef7.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 1,
              mx: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h1>
              <img src="logo.png" width={230} alt="Braunschweiger Biernadel" />
            </h1>
            <br />
            {/* <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange} aria-label="login or register" centered>
                    <Tab label="Login" value="login" />
                    <Tab label="Register" value="register" />
                  </TabList>
                </Box>
                <TabPanel value="login">
                  <Login />
                </TabPanel>
                <TabPanel value="register">
                  <SignUp />
                </TabPanel>
              </TabContext> */}

            <Typography sx={{ m: 4, textAlign: "center" }}>
              Lade die Braunschweiger Biernadel-App herunter.
            </Typography>

            <a href={appleAppStoreUrl}>
              <img
                src="Download_on_the_App_Store_Badge_DE_blk_092917.png"
                width={150}
              ></img>
            </a>
            <br />
            <a href={googlePlayStoreUrl}>
              <img src="de_badge_web_generic.png" width={150}></img>
            </a>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
