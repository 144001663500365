import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
// import * as jwt from "jsonwebtoken";
import { useEffect } from "react";
import { getLoggedInUser } from "../features/userSlice/userSlice";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Login } from "./Login";
import { useLocation } from "react-router";
import { Pathname } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// const isValidAccessTokenInStorage = () => {
//   const lastAuthUserEntries = Object.keys(window.localStorage).filter((key: string) => key.endsWith(".LastAuthUser"));
//   const lastAuthUserKey = lastAuthUserEntries[lastAuthUserEntries.length - 1];
//   if (lastAuthUserKey) {
//     const lastAuthUserName = window.localStorage.getItem(lastAuthUserKey);
//     if (lastAuthUserName) {
//       const authTokenName = Object.keys(window.localStorage).find((key: string) =>
//         key.endsWith(`.${lastAuthUserName}.accessToken`)
//       );
//       if (authTokenName) {
//         const authToken = window.localStorage.getItem(authTokenName);
//         if (authToken) {
//           const token = jwt.decode(authToken);
//           if (typeof token === "object" && !!token) {
//             const { exp } = token;
//             const now = Math.floor(Date.now() / 1000);
//             const isNotExpired = (exp ?? 0) > now;
//             return isNotExpired;
//           }
//         }
//       }
//     }
//   }
// };

export function LoginModal({
  path,
  forbidden,
}: {
  path: Pathname;
  forbidden?: Boolean;
}) {
  return (
    <Modal
      open={true}
      aria-labelledby="Access denied"
      aria-describedby="You need to be logged in to access this page."
    >
      <Box sx={style}>
        <Typography id="Access denied" variant="h6" component="h2">
          Bier verweigert
        </Typography>

        {forbidden ? (
          <Typography id="Forbidden." sx={{ mt: 2 }}>
            Fehlende Berechtigung.
          </Typography>
        ) : (
          <Typography
            id="You need to be logged in to access this page."
            sx={{ mt: 2 }}
          >
            Du musst angemeldet sein um diese Seite anzuzeigen.
          </Typography>
        )}
        <br />
        <Login from={path} />
      </Box>
    </Modal>
  );
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const dispatch = useAppDispatch();
  const me = useAppSelector((state: RootState) => state.user.loginMe);
  const location = useLocation();

  useEffect(() => {
    dispatch(getLoggedInUser());
  }, [dispatch, me.username]);

  if (me.username) {
    return children;
  }

  return <LoginModal path={location.pathname} />;
}

export function RequireAdmin({ children }: { children: JSX.Element }) {
  const dispatch = useAppDispatch();
  const me = useAppSelector((state: RootState) => state.user.loginMe);
  const location = useLocation();

  useEffect(() => {
    dispatch(getLoggedInUser());
  }, [dispatch, me.username]);

  if (me.username && me.groups?.includes("Admin")) {
    return children;
  }

  if (!me.username) {
    return <LoginModal path={location.pathname} />;
  }

  return <LoginModal path={location.pathname} forbidden={true} />;
}
