/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type locationInput = {
  id?: string | null,
  name: string,
  address: string,
  description: string,
  image?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  published?: boolean | null,
};

export type location = {
  __typename: "location",
  id: string,
  name: string,
  address: string,
  description: string,
  image: string,
  author?: string | null,
  createdAt: string,
  checkins?: string | null,
  published?: boolean | null,
  latitude: number,
  longitude: number,
};

export type checkinResult = {
  __typename: "checkinResult",
  location: string,
  createdAt: string,
};

export type messageInput = {
  message: string,
  to: string,
};

export type friendRequestResult = {
  __typename: "friendRequestResult",
  friendName: string,
  createdAt: string,
  requestTo?: string | null,
  requestFrom?: string | null,
};

export type acceptFriendRequestResult = {
  __typename: "acceptFriendRequestResult",
  friendName: string,
  acceptedAt: string,
};

export type keyAndValue = {
  key: string,
  value: string,
};

export type user = {
  __typename: "user",
  id: string,
  username?: string | null,
  updatedAt?: string | null,
  registeredAt?: string | null,
  lastLogin?: string | null,
  email?: string | null,
  avatar?: string | null,
  pushToken?: string | null,
};

export enum queryType {
  location = "location",
  checkinUser = "checkinUser",
  checkinLocation = "checkinLocation",
  user = "user",
  notification = "notification",
}


export type genericQueryResult = location | user | checkinUser | checkinLocation | friendship | notification


export type checkinUser = {
  __typename: "checkinUser",
  id: string,
  createdAt: string,
  location?: string | null,
};

export type checkinLocation = {
  __typename: "checkinLocation",
  id: string,
  createdAt: string,
  user: string,
  location?: string | null,
};

export type friendship = {
  __typename: "friendship",
  id: string,
  createdAt: string,
  friendName?: string | null,
  accepted: boolean,
  acceptedAt: string,
};

export type notification = {
  __typename: "notification",
  id: string,
  createdAt: string,
  perpetrator?: string | null,
  title?: string | null,
  message?: string | null,
  category?: NotificationCategory | null,
};

export enum NotificationCategory {
  checkin = "checkin",
  friendRequest = "friendRequest",
  friendAccepted = "friendAccepted",
  cheers = "cheers",
  beer = "beer",
}


export type queryMeResult = user | checkinUser | incomingFriendRequest | friendship | outgoingFriendRequest | notification


export type incomingFriendRequest = {
  __typename: "incomingFriendRequest",
  id: string,
  createdAt: string,
  friendName?: string | null,
  requestFrom: string,
};

export type outgoingFriendRequest = {
  __typename: "outgoingFriendRequest",
  id: string,
  createdAt: string,
  friendName?: string | null,
  requestTo: string,
};

export type uploadInfo = {
  __typename: "uploadInfo",
  uploadUrl?: string | null,
  bucketId?: string | null,
  authorizationToken?: string | null,
};

export type pdfGenData = {
  name?: string | null,
  url?: string | null,
};

export type AddLocationMutationVariables = {
  input: locationInput,
};

export type AddLocationMutation = {
  addLocation?:  {
    __typename: "location",
    id: string,
    name: string,
    address: string,
    description: string,
    image: string,
    author?: string | null,
    createdAt: string,
    checkins?: string | null,
    published?: boolean | null,
    latitude: number,
    longitude: number,
  } | null,
};

export type UpdateLocationMutationVariables = {
  input: locationInput,
};

export type UpdateLocationMutation = {
  updateLocation?:  {
    __typename: "location",
    id: string,
    name: string,
    address: string,
    description: string,
    image: string,
    author?: string | null,
    createdAt: string,
    checkins?: string | null,
    published?: boolean | null,
    latitude: number,
    longitude: number,
  } | null,
};

export type AddCheckinMutationVariables = {
  location: string,
};

export type AddCheckinMutation = {
  addCheckin?:  {
    __typename: "checkinResult",
    location: string,
    createdAt: string,
  } | null,
};

export type AddCheckinAndNotifyMutationVariables = {
  location: string,
};

export type AddCheckinAndNotifyMutation = {
  addCheckinAndNotify?:  {
    __typename: "checkinResult",
    location: string,
    createdAt: string,
  } | null,
};

export type BeerShoutMutationVariables = {
  location: string,
};

export type BeerShoutMutation = {
  beerShout?: string | null,
};

export type SendMessageMutationVariables = {
  input: messageInput,
};

export type SendMessageMutation = {
  sendMessage?: string | null,
};

export type AddFriendRequestMutationVariables = {
  friend: string,
};

export type AddFriendRequestMutation = {
  addFriendRequest?:  {
    __typename: "friendRequestResult",
    friendName: string,
    createdAt: string,
    requestTo?: string | null,
    requestFrom?: string | null,
  } | null,
};

export type RemoveFriendMutationVariables = {
  friend: string,
};

export type RemoveFriendMutation = {
  removeFriend?:  {
    __typename: "friendRequestResult",
    friendName: string,
    createdAt: string,
    requestTo?: string | null,
    requestFrom?: string | null,
  } | null,
};

export type AddFriendRequestAndNotifyMutationVariables = {
  friend: string,
};

export type AddFriendRequestAndNotifyMutation = {
  addFriendRequestAndNotify?:  {
    __typename: "friendRequestResult",
    friendName: string,
    createdAt: string,
    requestTo?: string | null,
    requestFrom?: string | null,
  } | null,
};

export type AcceptFriendRequestMutationVariables = {
  friend: string,
};

export type AcceptFriendRequestMutation = {
  acceptFriendRequest?:  {
    __typename: "acceptFriendRequestResult",
    friendName: string,
    acceptedAt: string,
  } | null,
};

export type AcceptFriendRequestAndNotifyMutationVariables = {
  friend: string,
};

export type AcceptFriendRequestAndNotifyMutation = {
  acceptFriendRequestAndNotify?:  {
    __typename: "acceptFriendRequestResult",
    friendName: string,
    acceptedAt: string,
  } | null,
};

export type EditMeMutationVariables = {
  input?: keyAndValue | null,
};

export type EditMeMutation = {
  editMe?:  {
    __typename: "user",
    id: string,
    username?: string | null,
    updatedAt?: string | null,
    registeredAt?: string | null,
    lastLogin?: string | null,
    email?: string | null,
    avatar?: string | null,
    pushToken?: string | null,
  } | null,
};

export type QueryByTypeQueryVariables = {
  input?: queryType | null,
};

export type QueryByTypeQuery = {
  queryByType:  Array<( {
      __typename: "location",
      id: string,
      name: string,
      address: string,
      description: string,
      image: string,
      author?: string | null,
      createdAt: string,
      checkins?: string | null,
      published?: boolean | null,
      latitude: number,
      longitude: number,
    } | {
      __typename: "user",
      id: string,
      username?: string | null,
      updatedAt?: string | null,
      registeredAt?: string | null,
      lastLogin?: string | null,
      email?: string | null,
      avatar?: string | null,
      pushToken?: string | null,
    } | {
      __typename: "checkinUser",
      id: string,
      createdAt: string,
      location?: string | null,
    } | {
      __typename: "checkinLocation",
      id: string,
      createdAt: string,
      user: string,
      location?: string | null,
    } | {
      __typename: "friendship",
      id: string,
      createdAt: string,
      friendName?: string | null,
      accepted: boolean,
      acceptedAt: string,
    } | {
      __typename: "notification",
      id: string,
      createdAt: string,
      perpetrator?: string | null,
      title?: string | null,
      message?: string | null,
      category?: NotificationCategory | null,
    }
  ) > | null,
};

export type QueryLocationQueryVariables = {
  locationId?: string | null,
};

export type QueryLocationQuery = {
  queryLocation?:  Array< {
    __typename: "location",
    id: string,
    name: string,
    address: string,
    description: string,
    image: string,
    author?: string | null,
    createdAt: string,
    checkins?: string | null,
    published?: boolean | null,
    latitude: number,
    longitude: number,
  } > | null,
};

export type SearchUserQueryVariables = {
  search?: string | null,
};

export type SearchUserQuery = {
  searchUser?: Array< string | null > | null,
};

export type QueryUserQueryVariables = {
  user?: string | null,
};

export type QueryUserQuery = {
  queryUser:  Array<( {
      __typename: "location",
      id: string,
      name: string,
      address: string,
      description: string,
      image: string,
      author?: string | null,
      createdAt: string,
      checkins?: string | null,
      published?: boolean | null,
      latitude: number,
      longitude: number,
    } | {
      __typename: "user",
      id: string,
      username?: string | null,
      updatedAt?: string | null,
      registeredAt?: string | null,
      lastLogin?: string | null,
      email?: string | null,
      avatar?: string | null,
      pushToken?: string | null,
    } | {
      __typename: "checkinUser",
      id: string,
      createdAt: string,
      location?: string | null,
    } | {
      __typename: "checkinLocation",
      id: string,
      createdAt: string,
      user: string,
      location?: string | null,
    } | {
      __typename: "friendship",
      id: string,
      createdAt: string,
      friendName?: string | null,
      accepted: boolean,
      acceptedAt: string,
    } | {
      __typename: "notification",
      id: string,
      createdAt: string,
      perpetrator?: string | null,
      title?: string | null,
      message?: string | null,
      category?: NotificationCategory | null,
    }
  ) | null > | null,
};

export type QueryMeQuery = {
  queryMe:  Array<( {
      __typename: "user",
      id: string,
      username?: string | null,
      updatedAt?: string | null,
      registeredAt?: string | null,
      lastLogin?: string | null,
      email?: string | null,
      avatar?: string | null,
      pushToken?: string | null,
    } | {
      __typename: "checkinUser",
      id: string,
      createdAt: string,
      location?: string | null,
    } | {
      __typename: "incomingFriendRequest",
      id: string,
      createdAt: string,
      friendName?: string | null,
      requestFrom: string,
    } | {
      __typename: "friendship",
      id: string,
      createdAt: string,
      friendName?: string | null,
      accepted: boolean,
      acceptedAt: string,
    } | {
      __typename: "outgoingFriendRequest",
      id: string,
      createdAt: string,
      friendName?: string | null,
      requestTo: string,
    } | {
      __typename: "notification",
      id: string,
      createdAt: string,
      perpetrator?: string | null,
      title?: string | null,
      message?: string | null,
      category?: NotificationCategory | null,
    }
  ) | null > | null,
};

export type QueryCheckinStatusQueryVariables = {
  location?: string | null,
};

export type QueryCheckinStatusQuery = {
  queryCheckinStatus?:  Array< {
    __typename: "checkinLocation",
    id: string,
    createdAt: string,
    user: string,
    location?: string | null,
  } | null > | null,
};

export type GetUploadInfoQuery = {
  getUploadInfo?:  {
    __typename: "uploadInfo",
    uploadUrl?: string | null,
    bucketId?: string | null,
    authorizationToken?: string | null,
  } | null,
};

export type GetLocationPdfQueryVariables = {
  input?: pdfGenData | null,
};

export type GetLocationPdfQuery = {
  getLocationPdf?: string | null,
};
