import * as React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router";
import {
  Place,
  ContactPage,
  Home,
  Info,
  Lightbulb,
  PrivacyTip,
} from "@mui/icons-material";

export default function DrawerMenu() {
  const navigate = useNavigate();

  const upperMenuItems = [
    {
      text: "Home",
      icon: <Home />,
      onClick: () => {
        navigate("/");
      },
    },
  ];
  const lowerMenuItems = [
    {
      text: "Lokale",
      icon: <Place />,
      onClick: () => {
        navigate("/lokale");
      },
    },

    {
      text: "Info",
      icon: <Lightbulb />,
      onClick: () => {
        navigate("/info");
      },
    },
    {
      text: "Datenschutz",
      icon: <PrivacyTip />,
      onClick: () => {
        navigate("/datenschutz");
      },
    },
    {
      text: "Impressum & Kontakt",
      icon: <ContactPage />,
      onClick: () => {
        navigate("/impressum");
      },
    },
  ];

  return (
    <>
      <List>
        {upperMenuItems.map((item) => (
          <ListItem button key={item.text} onClick={item.onClick}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {lowerMenuItems.map((item) => (
          <ListItem button key={item.text} onClick={item.onClick}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </>
  );
}
