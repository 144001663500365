import React, { useEffect } from "react";
import * as qrcode from "qrcode";

export default function LocationQrCode({ id }: { id: string }) {
  const canvasRef = React.useRef(null as any);

  useEffect(() => {
    if (id) {
      qrcode.toCanvas(canvasRef.current, `https://${process.env.REACT_APP_URL}/lokale/${id}/checkin`, (error: any) => {
        if (error) console.error(error);
      });
    }
  }, [id]);

  return (
    <>
      <br />
      <canvas ref={canvasRef}></canvas>
    </>
  );
}
