import jsQR from "jsqr";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "./app/hooks";
import { checkIn } from "./features/locationsSlice/locationsSlice";
import { open } from "./features/alertSlice/alertSlice";
import FullScreenDialog from "./FullScreenDialog";

function Scanner() {
  const [qrCodeRecognized, setQrCodeRecognized] = useState(false);
  const viedoRef = React.useRef(null as any);
  const canvasRef = React.useRef(null as any);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const checkUrlScheme = (url: string): boolean => {
    return url.indexOf(`https://${process.env.REACT_APP_URL}/lokale/`) === 0;
  };

  const stopVideo = (video: any) => {
    video.srcObject?.getTracks().forEach((track: any) => {
      track.stop();
    });
  };

  const drawLine = ({ canvas, begin, end, color }: { canvas: any; begin: any; end: any; color: any }) => {
    canvas.beginPath();
    canvas.moveTo(begin.x, begin.y);
    canvas.lineTo(end.x, end.y);
    canvas.lineWidth = 5;
    canvas.strokeStyle = color;
    canvas.stroke();
  };

  const drawLines = ({ canvas, code, color }: any) => {
    drawLine({ canvas, begin: code.location.topLeftCorner, end: code.location.topRightCorner, color });
    drawLine({
      canvas,
      begin: code.location.topRightCorner,
      end: code.location.bottomRightCorner,
      color,
    });
    drawLine({
      canvas,
      begin: code.location.bottomRightCorner,
      end: code.location.bottomLeftCorner,
      color,
    });
    drawLine({
      canvas,
      begin: code.location.bottomLeftCorner,
      end: code.location.topLeftCorner,
      color,
    });
  };

  const getScanImageFunction = ({ video, canvasElement, canvas }: any) => {
    const tick = async () => {
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        canvasElement.height = video.videoHeight;
        canvasElement.width = video.videoWidth;
        canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);

        const imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);

        const code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });

        const color = "#FF3B58";

        if (code && !qrCodeRecognized) {
          drawLines({ canvas, code, color });
          if (checkUrlScheme(code.data)) {
            const locationId = code.data
              .replace(`https://${process.env.REACT_APP_URL}/lokale/`, ``)
              .replace(`/checkin`, ``);
            setQrCodeRecognized(true);
            await dispatch(checkIn(locationId)).unwrap();
            dispatch(open({ type: "success", message: `Checked in to ${locationId}` }));
            stopVideo(video);
            navigate(`/lokale/${locationId}`);
          }
        }
      }
      requestAnimationFrame(tick);
    };

    return tick;
  };

  useEffect(() => {
    let animationFrameRequestId: number;
    const video = viedoRef.current;
    const canvasElement = canvasRef.current;
    const canvas = canvasElement?.getContext("2d");

    video?.setAttribute("autoplay", "");
    video?.setAttribute("muted", "");
    video?.setAttribute("playsinline", "");

    navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then((stream) => {
      video.srcObject = stream;
      const displayAndScanImageFunction = getScanImageFunction({ video, canvasElement, canvas });
      animationFrameRequestId = requestAnimationFrame(displayAndScanImageFunction);
    });

    return () => {
      cancelAnimationFrame(animationFrameRequestId);
      stopVideo(video);
    };
  }, []);

  return (
    <>
      <canvas ref={canvasRef}></canvas>
      <video ref={viedoRef} autoPlay hidden></video>
    </>
  );
}

export function CheckInScreen() {
  return (
    <FullScreenDialog goToOnClose={"/"}>
      <Scanner />
    </FullScreenDialog>
  );
}
