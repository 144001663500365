import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { IoBeerOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { imageBaseUrl } from "./Checkins";
import { Location } from "./Locations";

export default function LocationListItem({
  location,
  visited,
}: {
  location: Location;
  visited: boolean | undefined;
}) {
  const navigate = useNavigate();

  return (
    <Card sx={{ display: "flex", m: 3 }} key={location.name} elevation={3}>
      <CardMedia
        onClick={() => navigate(location.id)}
        className="pointer"
        component="img"
        sx={{ width: 151 }}
        image={`${imageBaseUrl}${location.image}`}
        alt={location.name}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          {/* {visited ? (
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              <Typography
                sx={{ color: "green", fontWeight: "bolder" }}
                component="span"
              >
                ✓
              </Typography>{" "}
              besucht
            </Typography>
          ) : (
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              noch nicht besucht
            </Typography>
          )} */}
          <Typography
            component="div"
            variant="h6"
            onClick={() => navigate(location.id)}
            className="pointer"
          >
            {location.name}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
            onClick={() => navigate(location.id)}
            className="pointer"
          >
            {location.description.substr(0, 60)}...
          </Typography>
          {/* <Typography variant="subtitle2" color="text.secondary" component="div">
            {location.address}
          </Typography> */}
          <Button
            sx={{ mt: 1, mr: 1 }}
            size="small"
            variant="contained"
            startIcon={<IoBeerOutline />}
            onClick={() => navigate(location.id)}
          >
            Zum Lokal
          </Button>
          <Button
            sx={{ mt: 1, mr: 1 }}
            startIcon={<QrCodeScannerIcon />}
            size="small"
            variant="contained"
            onClick={() => navigate("/checkin")}
          >
            Checkin
          </Button>
        </CardContent>
      </Box>
    </Card>
  );
}
