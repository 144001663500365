import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { API, graphqlOperation } from "aws-amplify";
import { useState } from "react";
import { searchUser } from "../graphql/queries";
import * as React from "react";
import { useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { UserList } from "./UserList";

export function UserSearch() {
  const [search, setSearch] = useState("");
  const [touched, setTouched] = useState(false);
  const [results, setResults] = useState([]);
  const searchRef = React.useRef();
  const me = useAppSelector((state: RootState) => state.user.me);

  const getResults = async (searchTerm: any) => {
    setSearch(searchTerm);
    if (searchTerm.length < 3) {
      setTouched(true);
      setResults([]);
      return;
    }
    const results = await API.graphql(graphqlOperation(searchUser, { search: searchTerm }));
    setResults((results as any).data?.searchUser ?? []);
  };

  return (
    <Box>
      <Paper component="form" sx={{ p: "2px 4px", mb: "10px", display: "flex", alignItems: "center", border: "1px solid lightgrey" }}>
        {/* <IconButton sx={{ p: "10px" }} aria-label="menu">
          <MenuIcon />
        </IconButton> */}
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Users"
          autoFocus={true}
          inputProps={{ "aria-label": "search users" }}
          ref={searchRef}
          onChange={(e) => getResults(e.target.value)}
        />
        {/* <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
        {/* <IconButton color="primary" sx={{ p: "10px" }} aria-label="clear">
          <ClearIcon />
        </IconButton> */}
      </Paper>
      {touched && search.length < 3 && search.length > 0 && (
        <Alert severity="info" sx={{ mt: 1 }}>
          Type at least 3 characters to see matching usernames
        </Alert>
      )}
      {touched && search.length > 3 && !results.length && (
        <Alert severity="warning" sx={{ mt: 1 }}>
          No results for "{search}". Please note that user names are case sensitive and the search must match the exact first characters of the user name.
        </Alert>
      )}
      {/* <label>
        Suche:
        <input type="text" name="name" onChange={(e) => getResults(e.target.value)} />
      </label> */}
      <UserList users={results} me={me} />
    </Box>
  );
}
