import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import alertReducer from "../features/alertSlice/alertSlice";
import locationsReducer from "../features/locationsSlice/locationsSlice";
import userIdentityReducer from "../features/userSlice/userSlice";

export const store = configureStore({
  reducer: {
    user: userIdentityReducer,
    locations: locationsReducer,
    alert: alertReducer,
  },
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
