import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { confirmUserSignUp, loginUser } from "../features/userSlice/userSlice";
import { open } from "../features/alertSlice/alertSlice";

export function ConfirmSignUp({ user, password }: { user: string; password: string }) {
  const [code, setCode] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const confirm = async (e: any) => {
    e.preventDefault();

    try {
      await dispatch(confirmUserSignUp({ user, code })).unwrap();
      await dispatch(loginUser({ user, password })).unwrap();
      await dispatch(open({ type: "success", message: `Willkommen! ${user} bestätigt!` }));
      navigate("/lokale", { replace: true });
    } catch (error) {
      dispatch(open({ type: "error", message: `Something went wrong confirming ${user} account` }));
    }
  };

  return (
    <Box sx={{ mx: "auto", pt: 1, alignContent: "center", textAlign: "center" }}>
      <form onSubmit={confirm}>
        <TextField
          required
          sx={{ pb: 2, width: 300 }}
          id="outlined-required"
          label="Confirmation Code"
          type="code"
          name="code"
          autoComplete="false"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <Button variant="contained" type="submit">
          Confirm
        </Button>
      </form>
    </Box>
  );
}
