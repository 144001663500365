import IconButton from "@mui/material/IconButton";

import { useNavigate } from "react-router-dom";
import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import * as style from "@dicebear/pixel-art";
import { createAvatar } from "@dicebear/avatars";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useAppDispatch } from "../app/hooks";
import PendingIcon from "@mui/icons-material/Pending";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  acceptRequest,
  requestFriend,
  UserDetails,
} from "../features/userSlice/userSlice";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

export const usersPath = "nutzer";

export function UserList({
  users,
  me: meDetails,
}: {
  users: string[];
  me: UserDetails;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <List
      sx={{ width: "100%", maxWidth: 800, pr: 3, bgcolor: "background.paper" }}
    >
      {(users ?? []).map((username) => {
        const avatar = `data:image/svg+xml;base64,${btoa(
          createAvatar(style, {
            seed: username,
            backgroundColor: "#099ac8",
            scale: 95,
          })
        )}`;

        const requestButton = () => {
          switch (true) {
            // user is me2
            case meDetails?.username === username:
              return <></>;
            // user is friend
            case meDetails.friends?.some(
              (friendData) => friendData.friendName === username
            ):
              return (
                <IconButton edge="end" aria-label="add">
                  <CheckCircleOutlineIcon />
                </IconButton>
              );
            // user is requested as friend
            case meDetails.outgoingFriendRequests?.some(
              (friendData) => friendData.friendName === username
            ):
              return (
                <IconButton
                  edge="end"
                  aria-label="add"
                  onClick={() =>
                    alert(
                      "Friend request is pending. If unanswered it will be deleted after two weeks."
                    )
                  }
                >
                  <PendingIcon />
                </IconButton>
              );
            // user requested friendship - accept button
            case meDetails.incomingFriendRequests?.some(
              (friendData) => friendData.friendName === username
            ):
              return (
                <IconButton
                  edge="end"
                  aria-label="add"
                  onClick={() => {
                    dispatch(acceptRequest(username));
                  }}
                >
                  <GroupAddIcon />
                </IconButton>
              );
            default:
              return (
                <IconButton
                  edge="end"
                  aria-label="add"
                  onClick={() => {
                    dispatch(requestFriend(username));
                  }}
                >
                  <PersonAddIcon />
                </IconButton>
              );
          }
        };
        return (
          <div key={username}>
            <ListItem alignItems="flex-start" secondaryAction={requestButton()}>
              <ListItemAvatar>
                <Avatar alt={username} src={avatar} />
              </ListItemAvatar>
              <ListItemText
                onClick={() =>
                  meDetails?.username === username
                    ? navigate(`/${usersPath}/me`)
                    : navigate(`/${usersPath}/${username}`)
                }
                className="pointer"
                primary={username}
                secondary={
                  <>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {meDetails?.username === username
                        ? "You"
                        : "Biernadel Member"}
                    </Typography>
                    {meDetails?.friends?.some(
                      (friendData) => friendData.friendName === username
                    ) && `   You are friends!`}
                  </>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </div>
        );
      })}
    </List>
  );
}
