import Box from "@mui/material/Box";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import "./App.css";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { RootState } from "./app/store";
import { getLoggedInUser, getMeDetails } from "./features/userSlice/userSlice";
import MenuAppBar from "./Navigation/AppBar";
import ReducedMenuAppBar from "./Navigation/ReducedAppBar";
import { Routing } from "./Navigation/Routing";

function App() {
  const dispatch = useAppDispatch();
  const me = useAppSelector((state: RootState) => state.user.loginMe);
  const meDetails = useAppSelector((state: RootState) => state.user.me);

  useEffect(() => {
    dispatch(getLoggedInUser());
    if (!meDetails.username && me.username) {
      dispatch(getMeDetails());
    }
  }, [dispatch, me.username, meDetails.username]);

  return <Routing />;
}

export function Layout() {
  const meDetails = useAppSelector((state: RootState) => state.user.me);
  const username = useAppSelector(
    (state: RootState) => state.user.loginMe.username
  );
  const location = useLocation();

  const isLocationUrl = (urlLocation: { pathname: string }) =>
    urlLocation?.pathname.includes("lokale/");
  const isRootUrl = (urlLocation: { pathname: string }) =>
    urlLocation?.pathname === "/";

  return (
    <>
      {isRootUrl(location) ? <ReducedMenuAppBar /> : <MenuAppBar />}
      <Box mx="auto" maxWidth={!isRootUrl(location) ? 800 : 4000}>
        <Outlet />
      </Box>
    </>
  );
  // }

  // return (
  //   <>
  //     <MenuAppBar />
  //     <SimpleBottomNavigation
  //       friendRequestCount={meDetails?.incomingFriendRequests?.length || 0}
  //     />
  //     <Box maxWidth={800} mx="auto">
  //       <Outlet />
  //     </Box>
  //     <Box sx={{ mt: "80px" }} />
  //   </>
  // );
}

export default App;
