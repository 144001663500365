import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

export default function Score({
  locationsVisitedCount,
  locationsTotal,
}: {
  locationsVisitedCount: number;
  locationsTotal: number;
}) {
  return (
    <Card
      sx={{
        minWidth: 275,
        textAlign: "center",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
      }}
      elevation={0}
    >
      <CardContent sx={{ alignItems: "center", alignContent: "center" }}>
        <Typography
          sx={{
            mx: "auto",
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
          }}
          variant="h5"
          component="div"
        >
          {`${locationsVisitedCount}/${locationsTotal}`} Lokale besucht
        </Typography>
        {locationsVisitedCount ? (
          <LinearProgress
            sx={{ width: "100%", height: "15px" }}
            variant="determinate"
            value={(locationsVisitedCount / (locationsTotal || 1)) * 100}
          />
        ) : (
          <></>
        )}
      </CardContent>
    </Card>
  );
}
