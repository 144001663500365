import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { RootState } from "./app/store";
import { close } from "./features/alertSlice/alertSlice";

export default function Alert() {
  const dispatch = useAppDispatch();
  const alert = useAppSelector((state: RootState) => state.alert);

  const handleClose = (event?: React.SyntheticEvent) => {
    dispatch(close());
  };

  return (
    <Snackbar
      open={alert.open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={alert.autoHideDuration || 6000}
      onClose={() => handleClose()}
    >
      <MuiAlert
        onClose={handleClose}
        severity={alert.type}
        sx={{ width: "100%" }}
      >
        {alert.message}
      </MuiAlert>
    </Snackbar>
  );
}
