import Button from "@mui/material/Button";
import { Auth } from "aws-amplify";

export function LogoutButton() {
  const signOut = async () => {
    await Auth.signOut();
    document.location.pathname = "/";
  };

  return (
    <Button variant="contained" type="submit" onClick={signOut}>
      Log Out
    </Button>
  );
}
