import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { acceptRequest, requestFriend } from "../features/userSlice/userSlice";
import Button from "@mui/material/Button";

export function FriendMgmtButton({ friend }: { friend: string }) {
  const me = useAppSelector((state: RootState) => state.user.me);
  const dispatch = useAppDispatch();

  // user is me
  if (me?.username === friend) {
    return <></>;
  }

  // user is already friend
  if (me.friends?.some((friendData) => friendData.friendName === friend)) {
    return (
      <div>
        <span>☑️ You are friends with {friend}!</span>
        <br />
        <Button sx={{ mt: "10px" }} variant="contained" disabled>
          Cancel Friendship
        </Button>
      </div>
    );
  }

  // user is already requested as friend
  if (me.outgoingFriendRequests?.some((friendData) => friendData.friendName === friend)) {
    return (
      <div>
        <span>
          Friendship request to {friend} pending! (It will be deleted automatically, if not accepted within two weeks.)
        </span>
        <br />
        <Button sx={{ mt: "10px" }} variant="contained" disabled>
          Cancel Friendship
        </Button>
      </div>
    );
  }

  // user requested me as friend
  if (me.incomingFriendRequests?.some((friendData) => friendData.friendName === friend)) {
    return (
      <div>
        <span>
          {friend} requests your friendship! (It will be deleted automatically, if not accepted within two weeks.)
        </span>
        <br />
        <Button
          sx={{ mt: "10px" }}
          variant="contained"
          onClick={() => {
            dispatch(acceptRequest(friend));
          }}
        >
          Accept Friendship Request
        </Button>
      </div>
    );
  }

  return (
    <Button
      sx={{ mt: "10px" }}
      variant="contained"
      onClick={() => {
        dispatch(requestFriend(friend));
      }}
    >
      Request Friendship
    </Button>
  );
}
