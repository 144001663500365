import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { LinearProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { location } from "./API";
import { useAppDispatch, useAppSelector, useFetch } from "./app/hooks";
import { RootState } from "./app/store";
import { RequireAuth } from "./Auth/Auth";
import { imageBaseUrl } from "./Checkins";
import {
  checkIn,
  CheckIn,
  getLocationDetails,
  getLocations,
} from "./features/locationsSlice/locationsSlice";
import LocationQrCode from "./LocationQrCode";
import LocationListItem from "./LocationsList";
import Score from "./Score";
import { groupBy } from "./User/Users";
import "./Location.css";
import { appleAppStoreUrl, googlePlayStoreUrl } from "./HomePage";
import { z } from "zod";

export interface LocationDetails {
  id: string;
  author: string;
  name: string;
  address: string;
  description: string;
  image: string;
}

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor;

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "iOS";
  }

  return "unknown";
}

export const locationSchema = z.object({
  locationCheckId: z.string(),
  version: z.number(),
  published: z.string(),
  createdAt: z.string(),
  address: z.string(),
  name: z.string(),
  image: z.string(),
  longitude: z.string(),
  sk: z.string(),
  description: z.string(),
  authorIp: z.array(z.string()),
  id: z.string(),
  latitude: z.string(),
  author: z.string(),
  __typename: z.string(),
});

export type Location = z.infer<typeof locationSchema>;

function Locations() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          // <RequireAuth>
          <LocationOverview />
          // </RequireAuth>
        }
      />
      <Route path=":locationId" element={<LocationDetailsView />} />
      <Route path=":locationId/:action" element={<LocationDetailsView />} />
    </Routes>
  );
}

export default Locations;

function LocationOverview() {
  // const dispatch = useAppDispatch();

  // const locations = useAppSelector(
  //   (state: RootState) => state.locations.locations
  // ).filter(
  //   (location: any) => location.published === true
  // ) as unknown as location[];

  // const meDetails = useAppSelector((state: RootState) => state.user.me);

  // useEffect(() => {
  //   dispatch(getLocations());
  // }, [dispatch]);
  const url =
    "https://6z0glc0gxd.execute-api.eu-west-1.amazonaws.com/public/public.getLocations";

  const { data } = useFetch<any>(url);

  const locations = data?.result?.data;

  // if (!locations?.length) {
  //   return <LinearProgress />;
  // }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ m: 2, maxWidth: "100%" }}
      >
        {/* <img
          src="long-logo.png"
          width={"100%"}
          alt="Braunschweiger Biernadel"
        /> */}
        <Typography variant="h4" color="text.primary" component="div">
          Stemplestellen der Braunschweiger Biernadel
        </Typography>
      </Box>
      {/* <Score
        locationsVisitedCount={
          Object.keys(groupBy<CheckIn>(meDetails.checkIns ?? [], "location"))
            .length
        }
        locationsTotal={locations.length}
      /> */}
      {/* {meDetails.checkIns && */}
      {locations?.map((location: Location) => {
        return (
          <LocationListItem
            key={location.id}
            location={location}
            visited={
              false // meDetails.checkIns
              // ?.map((c) => c.location)
              // .includes(
              //   visitedLocation?.id?.substring(4, visitedLocation?.id?.length)
              // )
            }
          />
        );
      })}
    </>
  );
}

function LocationDetailsView() {
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const locationDetails = useAppSelector(
    (state: RootState) =>
      state.locations.locationDetails[params.locationId as string]
  );
  const meDetails = useAppSelector((state: RootState) => state.user.me);
  const me = useAppSelector((state: RootState) => state.user.loginMe);
  const visits = meDetails.checkIns?.filter((checkin) =>
    locationDetails?.id?.includes(checkin.location)
  );
  const isAdmin = (me?.groups ?? []).includes("Admin");
  const [color, setColor] = useState();

  useEffect(() => {
    const { locationId, action } = params;
    const checkInToLocation = async (locationId: string): Promise<any> =>
      await dispatch(checkIn(locationId)).unwrap();
    if (locationId && action === "checkin" && me.username) {
      checkInToLocation(locationId);
      alert(`checked in to ${locationId}`);
      navigate(`/lokale/${locationId}`);
    }
  }, [dispatch, params, me.username, navigate]);

  useEffect(() => {
    document.title = `${locationDetails?.name || ""}${
      locationDetails?.name ? " - " : ""
    }Braunschweiger Biernadel`;
  }, [locationDetails]);

  useEffect(() => {
    const getLocationDetailsInHere = async (locationId: string): Promise<any> =>
      await dispatch(getLocationDetails(locationId)).unwrap();

    getLocationDetailsInHere(params.locationId as string);
  }, [params.locationId, dispatch]);

  if (!locationDetails?.name) {
    return <LinearProgress />;
  }

  return (
    <>
      <Box
        sx={{
          mx: "auto",
          my: 0,
          py: 0,
          textAlign: "center",
          // background: "linear-gradient(to right, beige -20%, #ffe493 100%);",
        }}
      >
        <img
          src={`${imageBaseUrl}${locationDetails.image}`}
          // width="100%"
          className="locationImage"
          // height="200px"
          alt={locationDetails?.name}
          loading="lazy"
        />
      </Box>
      <Box sx={{ m: 1 }}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          38100 Braunschweig
        </Typography>
        <Typography variant="h5" component="div">
          {locationDetails?.name}
        </Typography>
        <Typography color="text.secondary">
          {locationDetails?.address}
        </Typography>

        {
          // TODO: create a snackbar for this
          !me?.username && (
            <a
              style={{
                textDecoration: "none",
              }}
              href={
                getMobileOperatingSystem() === "Android"
                  ? googlePlayStoreUrl
                  : appleAppStoreUrl
              }
            >
              <Alert severity="info" sx={{ mt: 2 }}>
                <AlertTitle>Jetzt Bierstempel sammeln!</AlertTitle>
                <span>
                  Lade dir die Biernadel App im AppStore herrunter um bei{" "}
                  {locationDetails.name} und anderen Lokalen in Braunschweig
                  Stempel zu sammeln.
                  <Box sx={{ mt: 2 }}>
                    {getMobileOperatingSystem() === "Android" ? (
                      <a href={googlePlayStoreUrl}>
                        Im Google Play Store anzeigen
                      </a>
                    ) : (
                      <a href={appleAppStoreUrl}>Im App Store anzeigen</a>
                    )}
                  </Box>
                </span>
              </Alert>
            </a>
          )
        }

        <Typography sx={{ mt: 2 }} variant="body2">
          {locationDetails?.description}
        </Typography>
        {visits?.length ? (
          <Typography sx={{ mt: 2 }} variant="body2" color="text.secondary">
            ☑️ besucht{" "}
            {DateTime.fromISO(visits.slice(-1)[0].createdAt || "")
              .setLocale("de-DE")
              .toLocaleString({
                month: "long",
                day: "numeric",
                year: "numeric",
              })}{" "}
            {visits.length > 1 && `und ${visits.length - 1} Male zuvor`}
          </Typography>
        ) : (
          <></>
        )}
        <Button
          sx={{ m: 1, mt: 2 }}
          startIcon={<QrCodeScannerIcon />}
          size="small"
          variant="contained"
          onClick={() => navigate("/checkin")}
          disabled={!me?.username}
        >
          Checkin
        </Button>

        {/* <Button
          sx={{ m: 1, mt: 2 }}
          startIcon={<WebIcon />}
          size="small"
          variant="contained"
          disabled
        >
          Webseite
        </Button> */}

        {isAdmin && params.action === "showqr" && (
          <LocationQrCode id={locationDetails.id.substring(4)} />
        )}
      </Box>
    </>
  );
}
