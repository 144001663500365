import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import { getCurrentUser, loginUser } from "../features/userSlice/userSlice";
import { open } from "../features/alertSlice/alertSlice";
import { LogoutButton } from "./LogoutButton";
import { ConfirmSignUp } from "./ConfirmSignUp";
import { ResetPassword } from "./ResetPassword";
import { NewPassword } from "./NewPassword";

export function Login({from}: {from?: string}) {
  const me = useAppSelector((state: RootState) => state.user.loginMe);

  const [userNotConfirmed, setUserNotConfirmed] = useState(false);
  const [hasToSetNewPassword, setHasToSetNewPassword] = useState(false);
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [requestedPasswordReset, setRequestedPasswordReset] = useState(false);

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();

  const fromLocation = from || (location as any).state?.from?.pathname || "/lokale";

  if (me.username) {
    return (
      <>
        <span>You are logged in as {me.username}.</span>
        <LogoutButton />
      </>
    );
  }

  const login = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const login = await dispatch(loginUser({ user, password })).unwrap();

      if (login.challengeName === "NEW_PASSWORD_REQUIRED") {
        return setHasToSetNewPassword(true);
      }

      const getCurrentUserResult = await dispatch(getCurrentUser()).unwrap();

      dispatch(open({ type: "success", message: `Willkommen ${getCurrentUserResult.username}!` }));

      navigate(fromLocation, { replace: true });
    } catch (err) {
      console.error(err);
      if ((err as any).message === "User is not confirmed.") {
        setUserNotConfirmed(true);
      }
      if ((err as any).message === "Incorrect username or password.") {
        setWrongCredentials(true);
      }
    }
  };

  const requestPasswordReset = async () => {
    await Auth.forgotPassword(user);
    setRequestedPasswordReset(true);
  };

  if (userNotConfirmed) {
    return <ConfirmSignUp user={user} password={password} />;
  }

  if (requestedPasswordReset) {
    return (
      <>
        <Box sx={{ pb: 3 }}>
          <Alert severity="info">
            <span>Reset link sent to your email address.</span>
          </Alert>
        </Box>
        <ResetPassword user={user} />
      </>
    );
  }
  if (hasToSetNewPassword) {
    return (
      <>
        <span>Please set a new password.</span>
        <NewPassword user={user} oldPassword={password} />
      </>
    );
  }

  return (
    <>
      <Box sx={{ mx: "auto", pt: 1, alignContent: "center", textAlign: "center" }}>

        {wrongCredentials && !requestedPasswordReset && (
          <Box sx={{ mt: 3, pb: 3 }}>
            <Alert severity="error">
              <span>
                Falsche Daten.{" "}
                <span className="link" onClick={requestPasswordReset}>
                  Passwort zurücksetzen.
                </span>
              </span>
            </Alert>
          </Box>
        )}
        <form onSubmit={login}>
          <TextField
            required
            sx={{ pb: 2, width: 300 }}
            id="outlined-required"
            label="Nutzername"
            fullWidth
            autoFocus
            autoComplete="user"
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />
          <br />
          <TextField
            required
            sx={{ pb: 2, width: 300 }}
            id="outlined-required"
            label="Passwort"
            type="password"
            name="password"
            autoComplete="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <br />
          <Button variant="contained" type="submit">
            Submit
          </Button>
          {/* <label htmlFor="user">
          <input type="text" name="user" id="user" value={user} onChange={(e) => setUser(e.target.value)} />
        </label>
        <label htmlFor="password">
          <input
            type="password"
            name="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label> */}
          {/* <button type="submit">Signin</button> */}
        </form>
      </Box>
    </>
  );
}
