/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const queryByType = /* GraphQL */ `
  query QueryByType($input: queryType) {
    queryByType(input: $input) {
      ... on location {
        id
        name
        address
        description
        image
        author
        createdAt
        checkins
        published
        bierquest
        hide
        latitude
        longitude
      }
      ... on user {
        id
        username
        updatedAt
        registeredAt
        lastLogin
        email
        avatar
        pushToken
      }
      ... on checkinUser {
        id
        createdAt
        location
      }
      ... on checkinLocation {
        id
        createdAt
        user
        location
      }
      ... on friendship {
        id
        createdAt
        friendName
        accepted
        acceptedAt
      }
      ... on notification {
        id
        createdAt
        perpetrator
        title
        message
        category
      }
    }
  }
`;
export const queryLocation = /* GraphQL */ `
  query QueryLocation($locationId: String) {
    queryLocation(locationId: $locationId) {
      id
      name
      address
      description
      image
      author
      createdAt
      checkins
      published
      latitude
      longitude
    }
  }
`;
export const searchUser = /* GraphQL */ `
  query SearchUser($search: String) {
    searchUser(search: $search)
  }
`;
export const queryUser = /* GraphQL */ `
  query QueryUser($user: String) {
    queryUser(user: $user) {
      ... on location {
        id
        name
        address
        description
        image
        author
        createdAt
        checkins
        published
        latitude
        longitude
      }
      ... on user {
        id
        username
        updatedAt
        registeredAt
        lastLogin
        email
        avatar
        pushToken
      }
      ... on checkinUser {
        id
        createdAt
        location
      }
      ... on checkinLocation {
        id
        createdAt
        user
        location
      }
      ... on friendship {
        id
        createdAt
        friendName
        accepted
        acceptedAt
      }
      ... on notification {
        id
        createdAt
        perpetrator
        title
        message
        category
      }
    }
  }
`;
export const queryMe = /* GraphQL */ `
  query QueryMe {
    queryMe {
      ... on user {
        id
        username
        updatedAt
        registeredAt
        lastLogin
        email
        avatar
        pushToken
      }
      ... on checkinUser {
        id
        createdAt
        location
      }
      ... on incomingFriendRequest {
        id
        createdAt
        friendName
        requestFrom
      }
      ... on friendship {
        id
        createdAt
        friendName
        accepted
        acceptedAt
      }
      ... on outgoingFriendRequest {
        id
        createdAt
        friendName
        requestTo
      }
      ... on notification {
        id
        createdAt
        perpetrator
        title
        message
        category
      }
    }
  }
`;
export const queryCheckinStatus = /* GraphQL */ `
  query QueryCheckinStatus($location: String) {
    queryCheckinStatus(location: $location) {
      id
      createdAt
      user
      location
    }
  }
`;
export const getUploadInfo = /* GraphQL */ `
  query GetUploadInfo {
    getUploadInfo {
      uploadUrl
      bucketId
      authorizationToken
    }
  }
`;
export const getLocationPdf = /* GraphQL */ `
  query GetLocationPdf($input: pdfGenData) {
    getLocationPdf(input: $input)
  }
`;
