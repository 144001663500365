import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";

export function Impressum() {
  return (
    <Box sx={{mt: 3,  p: 1, textAlign: "left" }}>
      <Typography variant="h4" component="h2">
        Impressum
      </Typography>
      <br />
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Braunschweiger Biernadel ist eine werbefreie und kostenlose Anwendung
        und verfolgt kein kommerzielles Interesse.
      </Typography>
      <Typography variant="body1" sx={{ mt: 1 }} component="p">
        Diese Webseite und die Biernadel-Apps werden betrieben von:
        <br />
        <br />
        Robert Schulz &amp; Braunschweiger Bierfreunde
        <br />
        Pestalozzistr. 1<br />
        38114 Braunschweig
        <br />
        <br />
        Telefon: 0531 60185585
        <br />
        E-Mail-Adresse: biernadel@gmail.com
      </Typography>
    </Box>
  );
}
